import React from 'react'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'
import swal from 'sweetalert'
import s from 'styled-components'
import Cookies from 'js-cookie'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import RequestJoineClub from  '../presentation/request-join-club'
import ButtonToTop from '../btn-to-top'
import SignInComponent from '../presentation/sign-in'
import SignUpComponent from '../presentation/sign-up'
import ChangePasswordComponent from '../presentation/change-password'
import ResetPasswordComponent from '../presentation/reset-password'
import UpdateProfileComponent from '../presentation/profile'
import MenuMobile from './menu-mobile'
import LogoImage from '../../images/logo.svg'
import KMITLLogo from '../../images/KMITLLogo.png'
import ModalHandler from '../../utils/modal'
import { signOut } from '../../utils/auth-utils'
import { getProfile, updateProfile } from '../../utils/api'
import appActions from '../../state/app/actions'
import authActions from '../../state/auth/actions'
import clubRegisterActions from '../../state/club-register/actions'
import MediaQuery from 'react-responsive'

/* eslint-disable no-undef,jsx-a11y/anchor-is-valid */
const InputSearch = s.input`background: #eaeaea;padding: 0 5px;transition: width .35s ease-in-out;`

function goToPrice() {
  $('html, body').animate({ scrollTop: $('#price-table').offset().top }, 500)
}

const Logo = ({ auth, clubjoined, EventType, }) => {

  if (auth) {
    return (
      <div>
        <MediaQuery maxWidth={768}>
          {(clubjoined.joined == true || EventType == 'club_join') ?
            (
              <div className="pull-left club-logo-box">
                <div className="logo">
                  <Link to="/my-club">
                    <img src={KMITLLogo} alt="" width="30%" height="auto" />
                  </Link>
                </div>
              </div>) :
            (
              <div className="pull-left logo-box">
                <div className="logo">
                  <Link to="/home">
                    <img src={LogoImage} alt="" />
                  </Link>
                </div>
              </div>
            )}

        </MediaQuery>

        <MediaQuery minWidth={769}>
          {(clubjoined.joined == true || EventType == 'club_join') ?
            (
              <div className="pull-left club-logo-box">
                <div className="logo">
                  <Link to="/my-club">
                    <img src={KMITLLogo} alt="" width="50%" height="auto" />
                  </Link>
                </div>
              </div>
            ) :
            (
              <div className="pull-left logo-box">
                <div className="logo">
                  <Link to="/home">
                    <img src={LogoImage} alt="" />
                  </Link>
                </div>
              </div>
            )}
        </MediaQuery>
      </div>
    )
  } else {
    let isHideLogo = false
    if (typeof window !== `undefined`) {
      let { pathname } = window.location
      isHideLogo = pathname.indexOf('/temphidelogo') > -1
    }

    return (
      <React.Fragment>
        {!isHideLogo && (
          <div>
            <MediaQuery maxWidth={768}>
              {(window.location.pathname == '/KMITLEngineerAlumni' || EventType == 'club_join') ?
                null :
                (
                  <div className="pull-left logo-box">
                    <div className="logo">
                      <Link to="/home">
                        <img src={LogoImage} alt="" />
                      </Link>
                    </div>
                  </div>
                )}
            </MediaQuery>

            <MediaQuery minWidth={769}>
              <div>
                {(window.location.pathname == '/KMITLEngineerAlumni' || EventType == 'club_join') ?
                  (
                    <div className="pull-left club-logo-box">
                      <div className="logo">
                        <Link to="/my-club">
                          <img src={KMITLLogo} alt="" width="50%" height="auto" />
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="pull-left logo-box">
                      <div className="logo">
                        <Link to="/">
                          <img src={LogoImage} alt="" />
                        </Link>
                      </div >
                    </div >
                  )
                }
              </div>
            </MediaQuery>
          </div>
        )}
      </React.Fragment >
    )
  }
}

async function onSignOut() {
  try {
    signOut()
    Cookies.remove('IS_REMEMBER')
    Cookies.remove('_id')
    navigate('/')
  } catch (e) {
    console.log(e)
  }
}

function gotoPage() {
  const { id, invitation_id, invitation_pin } = queryString.parse(
    window.location.search
  )
  if (
    typeof id !== 'undefined' &&
    typeof invitation_id !== 'undefined' &&
    typeof invitation_pin !== 'undefined'
  ) {
    let text = `id=${id}&invitation_id=${invitation_id}&invitation_pin=${invitation_pin}`
    navigate(`/event-detail/?${text}`)
  } else {
    navigate(`/my-club`)
  }
}

function submitSignIn() {
  ModalHandler.closeModal('modalSignIn')
  gotoPage()
}

function submitSignUp() {
  ModalHandler.closeModal('modalSignUp')
  gotoPage()
}

function switchSignInToSignUp() {
  ModalHandler.closeModal('modalSignIn')
  ModalHandler.openModal('modalSignUp')
}

function switchSignInToRquestJoinClub() {
  ModalHandler.closeModal('modalSignIn')
  ModalHandler.openModal('modalJoinClub')
}

function switchSignInToForgotPassword() {
  ModalHandler.closeModal('modalSignIn')
  ModalHandler.openModal('modalResetPassword')
}

function switchSignUpToSignIn() {
  ModalHandler.closeModal('modalSignUp')
  ModalHandler.openModal('modalSignIn')
}

const ModalChangePassword = props => (
  <div id="modalChangePassword" className="modal" style={{ paddingTop: 30 }}>
    <div className="modal-content" style={{ maxWidth: 800 }}>
      <ChangePasswordComponent
        auth={props.auth}
        onClose={() => ModalHandler.closeModal('modalChangePassword')}
        onSubmit={props.onSubmit}
        lang={props.lang}
        locale={props.locale}
      />
    </div>
  </div>
)

const ModalResetPassword = props => (
  <div id="modalResetPassword" className="modal" style={{ paddingTop: 30 }}>
    <div className="modal-content" style={{ maxWidth: 800 }}>
      <ResetPasswordComponent
        onClose={() => ModalHandler.closeModal('modalResetPassword')}
        onSubmit={props.onSubmit}
        onResetPassword={props.onResetPassword}
        lang={props.lang}
        locale={props.locale}
        SelectBanner={props.SelectBanner}
      />
    </div>
  </div>
)

const ModalSignIn = props => (
  <div
    id="modalSignIn"
    className="modal"
    style={{ paddingTop: 30, display: 'none' }}
  >
    <div className="modal-content" style={{ maxWidth: 900 }}>
      <SignInComponent
        lang={props.lang}
        onClose={() => ModalHandler.closeModal('modalSignIn')}
        onSubmit={submitSignIn}
        onForgotPassword={switchSignInToForgotPassword}
        onSignFacebook={() => console.log('onSignFacebook')}
        onSignUp={switchSignInToSignUp}
        onRequestJoinClub={switchSignInToRquestJoinClub}
        locale={props.locale}
        SelectBanner={props.SelectBanner}
      />
    </div>
  </div>
)

const ModalSignUp = props => (
  <div
    id="modalSignUp"
    className="modal"
    style={{ paddingTop: 30, display: 'none' }}
  >
    <div className="modal-content" style={{ maxWidth: 1080 }}>
      <SignUpComponent
        lang={props.lang}
        onClose={() => ModalHandler.closeModal('modalSignUp')}
        onSubmit={submitSignUp}
        onSignInFacebook={() => console.log('onSignInFacebook')}
        onSignIn={switchSignUpToSignIn}
        locale={props.locale}
      />
    </div>
  </div>
)

const ModalJoinClub = props => (
  <div
    id="modalJoinClub"
    className="modal"
    style={{ paddingTop: 30, display: 'none' }}
  >
    <div className="modal-content" style={{ maxWidth: 900 }}>
      <RequestJoineClub
        lang={props.lang}
        onClose={() => ModalHandler.closeModal('modalJoinClub')}
        onSubmit={submitSignIn}
        onForgotPassword={switchSignInToForgotPassword}
        onSignFacebook={() => console.log('onSignFacebook')}
        onSignUp={switchSignInToSignUp}
        locale={props.locale}
        SelectBanner={props.SelectBanner}
      />
    </div>
  </div>
)
function submitEditProfile(locale) {
  return async formData => {
    const resData = await updateProfile({
      phone_number: formData.phoneNumber,
      name: formData.fullName,
      picture: formData.avartar,
    })
    if (resData.code === 'OK') {
      swal(locale.SUCCESS, locale.SAVE_SUCCESS, 'success')
      ModalHandler.closeModal('modalUpdateProfile')
    } else {
      if (resData.text.message) {
        swal(locale.ERROR, resData.text.message, 'error')
      } else {
        swal(locale.ERROR, resData.text, 'error')
      }
    }
  }
}

const ModalUpdateProfile = props => (
  <div id="modalUpdateProfile" className="modal" style={{ paddingTop: 30 }}>
    <div className="modal-content" style={{ maxWidth: 450 }}>
      <UpdateProfileComponent
        data={props.data}
        onClose={() => ModalHandler.closeModal('modalUpdateProfile')}
        onSubmit={submitEditProfile(props.locale)}
        locale={props.locale}
      />
    </div>
  </div>
)

function toggleClassActiveMenu(pathMatch) {
  let { pathname } = window.location
  const a = pathname.substring(pathname.length - 1, pathname.length)
  if (a === '/') {
    pathname = pathname.substring(0, pathname.length - 1)
  }
  if (typeof pathMatch === 'string') {
    return pathMatch === pathname ? 'current' : ''
  } else if (typeof pathMatch === 'object') {
    return pathMatch.indexOf(pathname) > -1 ? 'current' : ''
  } else {
    return ''
  }
}

const MemberMenu = ({
  authx,
  profileData,
  onOpenEditProfile,
  lang = 'EN',
  locale,
  onChangeLanguage,
  onNewPassword,
  onSearch,
  onForgotPassword,
  resetPassword,
  clubjoined,
  SelectBanner,
}) => {
  return (
    <div className="nav-outer clearfix menu-nav-outer">
      <nav className="main-menu navbar-expand-md">
        <MenuMobile
          auth
          onOpenEditProfile={onOpenEditProfile}
          locale={locale}
          lang={lang}
          onChangeLanguage={onChangeLanguage}
          clubjoined={clubjoined}
        />
        <div
          className="navbar-collapse collapse clearfix"
          id="navbarSupportedContent"
        >
          <ul className="navigation clearfix">
            {clubjoined.joined == true ? null :
              (
                <li
                  className={`${toggleClassActiveMenu([
                    '/home',
                    '/event-detail',
                    '/buy-ticket',
                    '/search',
                  ])} dropdown`}
                >
                  <Link to="/home">
                    <span>{locale.HOME}</span>
                  </Link>
                </li>
              )}
            {clubjoined.joined == true ? null :
              (
                <li
                  className={`${toggleClassActiveMenu([
                    '/my-events',
                    '/my-event-all',
                    '/event-detail/edit',
                    '/invitations',
                    '/transactions',
                    '/upcoming',
                    '/joined',
                    '/history',
                    '/payment',
                    '/public-list',
                  ])} dropdown`}
                >
                  <Link to="/my-events">
                    <span>{locale.MY_EVENT}</span>
                  </Link>
                  <ul>
                    <li>
                      <Link to="/my-events">{locale.MY_EVENT}</Link>
                    </li>
                    <li>
                      <Link to="/upcoming">{locale.UPCOMING}</Link>
                    </li>
                    <li>
                      <Link to="/joined">{locale.JOINED}</Link>
                    </li>
                    <li>
                      <Link to="/history">{locale.HISTORY}</Link>
                    </li>
                    <li>
                      <Link to="/public-list">{locale.PUBLIC}</Link>
                    </li>
                  </ul>
                  <div className="dropdown-btn">
                    <i className="fa fa-angle-down" />
                  </div>
                </li>
              )}
            {clubjoined.joined == true ? null :
              (
                <li
                  className={`${toggleClassActiveMenu([
                    '/home',
                    '/event-detail',
                    '/buy-ticket',
                    '/search',
                  ])} dropdown`}
                >
                  <Link to="/club-list">
                    <span>{locale.MY_CLUB}</span>
                  </Link>
                </li>
              )}
            {clubjoined.joined == true ? null :
              (
                <li className={`${toggleClassActiveMenu(['/account'])} dropdown`}>
                  <a>
                    <span>{locale.ACCOUNT}</span>
                  </a>
                  <ul>
                    <li onClick={onOpenEditProfile}>
                      <a>{locale.EDIT_PROFILE}</a>
                    </li>
                    <li
                      onClick={() => ModalHandler.openModal('modalChangePassword')}
                    >
                      <a>{locale.CHANGE_PASSWORD}</a>
                    </li>
                    <li>
                      <Link to="/payment/history">{locale.PAYMENT_HISTORY}</Link>
                    </li>
                    <li>
                      {lang === 'EN' ? (
                        <a onClick={() => onChangeLanguage('TH')}>ไทย</a>
                      ) : (
                          <a onClick={() => onChangeLanguage('EN')}>English</a>
                        )}
                    </li>
                    <li>
                      <a onClick={onSignOut}>{locale.SIGN_OUT}</a>
                    </li>
                  </ul>
                  <div className="dropdown-btn">
                    <i className="fa fa-angle-down" />
                  </div>
                </li>
              )}
            {clubjoined.joined == true ? null :
              (
                <li>
                  <a style={{ marginLeft: 10 }} onClick={onSearch}>
                    <i className="fa fa-search" style={{ color: '#776D6D' }} />
                  </a>
                </li>
              )}
            {clubjoined.joined == true ?
              (<li>
                {lang === 'EN' ? (
                  <a onClick={() => onChangeLanguage('TH')}>ไทย</a>
                ) : (
                    <a onClick={() => onChangeLanguage('EN')}>English</a>
                  )}
              </li>) : null}
          </ul>
        </div>
      </nav>
      <ModalUpdateProfile data={profileData} locale={locale}  />
      <ModalChangePassword
        lang={lang}
        locale={locale}
        auth={authx}
        onSubmit={onNewPassword}
      />
      <ModalResetPassword
        locale={locale}
        lang={lang}
        onSubmit={onForgotPassword}
        onResetPassword={resetPassword}
        SelectBanner={SelectBanner}
      />
      <ModalSignIn locale={locale} lang={lang} SelectBanner={SelectBanner}/>
      <ModalJoinClub locale={locale} lang={lang} SelectBanner={SelectBanner}/>
      <ModalSignUp locale={locale} lang={lang} />
    </div>
  )
}

const NonMemberMenu = ({
  locale,
  lang = 'EN',
  onChangeLanguage,
  goToPrice,
  onForgotPassword,
  resetPassword,
  clubjoined,
  SelectBanner,
  defaultBanner,
}) => {
  return (
    <div className="nav-outer clearfix menu-nav-outer">
      <nav className="main-menu navbar-expand-md">
      { window.location.pathname == '/KMITLEngineerAlumni' ? null :
      (
        <MenuMobile
          locale={locale}
          lang={lang}
          onChangeLanguage={onChangeLanguage}
          clubjoined={clubjoined}
        />
      )}
        <div
          className="navbar-collapse collapse clearfix"
          id="navbarSupportedContent"
        >
          <ul className="navigation clearfix">
            {window.location.pathname == '/KMITLEngineerAlumni' ? null :
              (
                <li className="current ">
                  <Link to="/">
                    <span>{locale.HOME}</span>
                  </Link>
                </li>
              )}
            {window.location.pathname == '/KMITLEngineerAlumni' ? null :
              (
                <li onClick={goToPrice}>
                  <a>
                    <span>{locale.PRICING}</span>
                  </a>
                </li>
              )}
            {window.location.pathname == '/KMITLEngineerAlumni' ? null :
              (
                <li>
                  <Link to="/club-list">
                    <span>{locale.MY_CLUB}</span>
                  </Link>
                </li>
              )}
            <li onClick={() => onChangeLanguage(lang === 'EN' ? 'TH' : 'EN')}>
              <a>
                <span>{lang === 'EN' ? 'ไทย' : 'English'}</span>
              </a>
            </li>
            {window.location.pathname == '/KMITLEngineerAlumni' ? null :
              (
              <li onClick={() => {defaultBanner()}}>
                  <a>
                    <span
                      className="nav-text-sign-in"
                      style={{ cursor: 'pointer' }}
                    >
                      {locale.SIGN_IN}
                    </span>
                  </a>
                </li>
              )}
            {window.location.pathname == '/KMITLEngineerAlumni' ? null :
              (
                <li>
                  <a>
                    <button
                      className="nav-btn-sign-up"
                      onClick={() => ModalHandler.openModal('modalSignUp')}
                    >
                      {locale.SIGN_UP}
                    </button>
                  </a>
                </li>
              )}
          </ul>
        </div>
      </nav>
      <ModalResetPassword
        locale={locale}
        lang={lang}
        onSubmit={onForgotPassword}
        onResetPassword={resetPassword}
        SelectBanner={SelectBanner}
      />
      <ModalSignIn locale={locale} lang={lang} SelectBanner={SelectBanner}/>
      <ModalJoinClub locale={locale} lang={lang} SelectBanner={SelectBanner}/>
      <ModalSignUp locale={locale} lang={lang} />
    </div>
  )
}

const Menu = ({
  auth,
  authx,
  profileData,
  onOpenEditProfile,
  lang = 'EN',
  locale,
  onChangeLanguage,
  onForgotPassword,
  onNewPassword,
  resetPassword,
  onSearch,
  clubjoined,
  SelectBanner,
  defaultBanner,
}) => {
  if (!locale) {
    return null
  }
  if (auth) {
    return (
      <MemberMenu
        authx={authx}
        profileData={profileData}
        onOpenEditProfile={onOpenEditProfile}
        lang={lang}
        locale={locale}
        onChangeLanguage={onChangeLanguage}
        onNewPassword={onNewPassword}
        onSearch={onSearch}
        onForgotPassword={onForgotPassword}
        resetPassword={resetPassword}
        clubjoined={clubjoined}
        SelectBanner={SelectBanner}
      />
    )
  } else {
    return (
      <NonMemberMenu
        locale={locale}
        lang={lang}
        onChangeLanguage={onChangeLanguage}
        goToPrice={goToPrice}
        onForgotPassword={onForgotPassword}
        resetPassword={resetPassword}
        SelectBanner={SelectBanner}
        defaultBanner={defaultBanner}
      />
    )
  }
}

class HeaderLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profileData: {
        email: '',
        name: '',
        picture: '',
        phoneNumber: '',
      },
    }
    this.clickEditProfile = this.clickEditProfile.bind(this)
  }

  componentDidMount() {
    $(window).on('scroll', () => {
      this.headerStyle()
    })
    window.AppleID.auth.init({
      clientId: 'me.invitree.service',
      scope: 'name email',
      redirectURI: 'https://invitree-web.armspkt.now.sh/callback',
      // state: '[STATE]'
    })
  }

  onSearch = () => {
    navigate(`/search`)
  }

  //  Update Header Style and Scroll to Top
  headerStyle() {
    if ($('.corpo-header').length) {
      const windowpos = $(window).scrollTop()
      const siteHeader = $('.corpo-header')
      const stickyHeader = $('.sticky-header')
      const scrollLink = $('.scroll-top')
      if (windowpos >= 200) {
        siteHeader.addClass('fixed-header')
        stickyHeader.css('visibility', 'visible')
        stickyHeader.css('opacity', '1')
        scrollLink.css('opacity', '1')
      } else {
        siteHeader.removeClass('fixed-header')
        stickyHeader.css('visibility', 'hidden')
        stickyHeader.css('opacity', '0')
        scrollLink.css('opacity', '0')
      }
    }
  }

  async clickEditProfile() {
    const resData = await getProfile()
    if (resData.code === 'OK') {
      this.setState({ profileData: resData.data }, () => {
        ModalHandler.openModal('modalUpdateProfile')
      })
    } else {
      const locale = this.props.app.locale[this.props.app.lang]
      swal(locale.ERROR, resData.text, 'error')
    }
  }

  render() {
    const auth = this.props.auth
    const EventType = this.props.EventType
    const clubjoined = this.props.clubjoined
    const SelectBanner = this.props.SelectBanner
    const defaultBanner = this.props.defaultBanner
    const NavMenu = () => (
      <div className="default-container">
        <div className="clearfix">
          <Logo
            auth={auth}
            clubjoined={clubjoined}
            EventType={EventType}
          />
          <Menu
            auth={auth}
            onChangeSearch={this.onChangeSearch}
            authx={this.props.authx}
            profileData={this.state.profileData}
            onOpenEditProfile={this.clickEditProfile}
            lang={this.props.app.lang}
            locale={this.props.app.locale[this.props.app.lang]}
            onChangeLanguage={this.props.onChangeLanguage}
            onForgotPassword={this.props.forgotPassword}
            onNewPassword={this.props.newPassword}
            resetPassword={this.props.resetPassword}
            onSearch={this.onSearch}
            clubjoined={clubjoined}
            SelectBanner={SelectBanner}
            defaultBanner={defaultBanner}
          />
        </div>
      </div>
    )
    return (
      <header className="corpo-header">
        <div className="header-upper">
          <NavMenu />
        </div>
        <div className="sticky-header">
          <NavMenu />
        </div>
        <ButtonToTop />
      </header>
    )
  }
}

const mapStateToProps = state => {
  return {
    app: state.app,
    authx: state.auth,
    clubjoined: state.clubRegister,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      onChangeLanguage: appActions.onChangeLanguage,
      forgotPassword: authActions.forgotPassword,
      newPassword: authActions.changePassword,
      resetPassword: authActions.resetPassword,
      onSetProfile: clubRegisterActions.onSetProfile,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderLayout)

/**
 <div className="menu-search-box">
 <div className="dropdown">
 <button className="menu-sb-btn dropdown-toggle" type="button" id="dropdownMenu4" data-toggle="dropdown"
 aria-haspopup="true" aria-expanded="false"><i
 className="fa fa-search"/></button>
 <ul className="dropdown-menu pull-right search-panel" aria-labelledby="dropdownMenu4">
 <li className="panel-outer">
 <div className="form-container">
 <form method="post" action="#">
 <div className="form-group">
 <input type="search" name="field-name" defaultValue placeholder="Search Here" required/>
 <button type="submit" className="search-btn"><i className="fa fa-search"/></button>
 </div>
 </form>
 </div>
 </li>
 </ul>
 </div>
 </div>
 */
