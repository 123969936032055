import React from 'react'
import s from 'styled-components'

import { getLanguage } from '../../locale'
import {
  ConvertISODate1,
  convertTransactionOrderType,
  toUpperCaseFirst,
} from '../../utils/string'
import { Modal, ModalClose, ModalContent, ModalHeader } from './index'

const lang = getLanguage().lang
const Body = s.div`text-align:center;padding: 10px;`
const Table = s.table`
  width:100%;
  & > tbody > tr > td {
    padding: 10px;
    text-align: left;
  }
  `
const TdRed = s.td`color:#C14C40;`

function PaymentHistoryDetail({ data, locale }) {
  return (
    <Modal id="modalPaymentHistoryDetail">
      <ModalContent maxWidth={400}>
        <ModalHeader
          id="modalPaymentHistoryDetail"
          title={locale.PAYMENT_HISTORY_DETAIL}
        />
        <Body>
          {data.id === 0 ? (
            <h1>Loading...</h1>
          ) : (
            <Table>
              <tbody>
                <tr>
                  <td>{locale.DATE}:</td>
                  <TdRed>{ConvertISODate1(data.created_at)}</TdRed>
                </tr>
                <tr>
                  <td>Transaction ID:</td>
                  <TdRed>{data.id}</TdRed>
                </tr>
                <tr>
                  <td>{locale.EVENT}:</td>
                  <TdRed>
                    {(lang === 'TH'
                      ? data.event_title_th
                      : data.event_title_en) || '-'}
                  </TdRed>
                </tr>
                <tr>
                  <td>{locale.TYPE}:</td>
                  <TdRed>
                    {data.order
                      ? convertTransactionOrderType(
                          data.order.order_type,
                          locale.locale
                        ) || '-'
                      : '-'}
                  </TdRed>
                </tr>
                <tr>
                  <td>{locale.CHANNEL}:</td>
                  <TdRed>
                    {data.payment_method === 'internet_banking'
                      ? 'Internet Banking'
                      : data.payment_method === 'credit_card'
                      ? 'Credit Card'
                      : data.payment_method}
                  </TdRed>
                </tr>
                <tr>
                  <td>{locale.AMOUNT}:</td>
                  <TdRed>
                    {data.amount} {data.currency.toUpperCase()}
                  </TdRed>
                </tr>
                <tr>
                  <td>{locale.STATUS}:</td>
                  <TdRed>{toUpperCaseFirst(data.status)}</TdRed>
                </tr>
              </tbody>
            </Table>
          )}
        </Body>
        <ModalClose id="modalPaymentHistoryDetail" textClose={locale.CLOSE} />
      </ModalContent>
    </Modal>
  )
}

export default PaymentHistoryDetail

PaymentHistoryDetail.defaultProps = {
  data: {
    id: 1,
    created_at: '2019-01-21T00:35:05.318363Z',
    event_name: '',
    type: '',
    payment_method: '',
    amount: '',
    status: '',
    currency: 'thb',
  },
  locale: null,
}
