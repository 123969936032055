import React from 'react'
/* eslint-disable no-undef */
function goToTop() {
  $('html, body').animate({ scrollTop: $('html').offset().top }, 500)
}

export default () => (
  <div
    className="scroll-top scroll-target"
    data-target="html"
    style={{ display: 'block', opacity: 0 }}
    onClick={goToTop}
  >
    <i className="fa fa-angle-up" />
  </div>
)
