import React from 'react'
import swal from 'sweetalert'
import Input from '../form/input'
import Button from '../form/buttons'
import ImageCover from '../../images/reset-password-cover.png'

const style1 = { padding: '10px 10px 30px 0' }
const style2 = { textAlign: 'center', padding: 10 }
const style3 = { padding: 5 }

export default class ResetPasswordComponent extends React.Component {
  input = null

  state = {
    isVerification: false,
    email: '',
    code: '',
    BannerTH: process.env.GATSBY_IMG_SIGN_IN_TH,
    BannerEN: process.env.GATSBY_IMG_SIGN_IN_EN,
    SelectClub: '',
    isEmailvalid: false,
  }

  componentDidMount() { this.ConvertBanner() }

  // Recieve SelectBanner from header.js or sign page to choose which clubbanner is displayed
  ConvertBanner() {
    const SelectBanner = this.props.SelectBanner
    switch (SelectBanner) {
      case 'Default':
        this.setState({
          BannerTH: process.env.GATSBY_IMG_SIGN_UP_TH,
          BannerEN: process.env.GATSBY_IMG_SIGN_IN_EN,
          isJoineRequest: false,
        })
        break
      case 'KMITL':
        this.setState({
          BannerTH: process.env.GATSBY_IMG_SIGN_IN_KMITL,
          BannerEN: process.env.GATSBY_IMG_SIGN_IN_KMITL,
          isJoineRequest: true,
          SelectClub: 'KMITLEngineerAlumni',
        })
        break
    }
  }

  onChangeCode = code => this.setState({ code })
  onChangePass1 = password => this.setState({ password })
  onChangePass2 = password2 => this.setState({ password2 })
  onSubmit = () => this.props.onSubmit(this.state.email, this.onSubmitSuccess)
  onSubmitResetPassword = () => {
    const { email, code, password, password2 } = this.state
    if (password !== password2) {
      swal(
        this.props.locale.Error,
        this.props.locale.ERROR_PASSWORD_NOT_MATCH,
        'error'
      )
    } else {
      this.props.onResetPassword(
        email,
        code,
        password,
        this.onResetPasswordSuccess
      )
    }
  }

  onSubmitSuccess = () => {
    this.setState({ isVerification: true })
  }
  onResetPasswordSuccess = () => {
    swal(
      this.props.locale.SUCCESS,
      this.props.locale.WARNING_RESET_PASSWORD_SUCCESS,
      'success'
    ).then(() => {
      this.props.onClose()
    })
  }

  emailValidation(email) {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      this.setState({ email: email, isEmailvalid: true })
    } else {
      this.setState({ isEmailvalid: false })
    }
  }
  render() {
    return (
      <div className="panel-sign-in">
        <div className="panel-sign-in-left">
          <img
            src={
              this.props.lang === 'TH'
                ? this.state.BannerTH
                : this.state.BannerEN
            }
            alt=""
          />
        </div>
        <div className="panel-sign-in-right">
          <div style={style1}>
            <span className="close" onClick={this.props.onClose}>
              &times;
            </span>
          </div>
          {this.state.isVerification ? (
            <React.Fragment>
              <Input.Textbox
                title={this.props.locale.VERIFICATION_CODE}
                placeholder={`${this.props.locale.VERIFICATION_CODE.toLowerCase()}...`}
                onChange={this.onChangeCode}
              />
              <Input.Textbox
                title={this.props.locale.PASSWORD}
                placeholder={`${this.props.locale.PASSWORD.toLowerCase()}...`}
                type="password"
                onChange={this.onChangePass1}
              />
              <Input.Textbox
                title={this.props.locale.CONFIRM_PASSWORD}
                placeholder={`${this.props.locale.CONFIRM_PASSWORD.toLowerCase()}...`}
                type="password"
                onChange={this.onChangePass2}
              />
            </React.Fragment>
          ) : (
              <Input.Textbox
                title={this.props.locale.EMAIL}
                placeholder={`${this.props.locale.EMAIL.toLowerCase()}...`}
                onChange={email => this.emailValidation(email)}
              />
            )}
          <div style={style2}>
            <div style={style3}>
              {this.state.isVerification ? (
                <Button.Button4 onClick={this.onSubmitResetPassword}>
                  {this.props.locale.RESET}
                </Button.Button4>
              ) : (
                  <Button.Button4 
                    onClick={this.onSubmit}
                    disabled={!this.state.isEmailvalid}
                    >
                    {this.props.locale.RESET}
                  </Button.Button4>
                )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
