import React from 'react'
import s from 'styled-components'

const Box = s.div`
  padding-left: 20px;
  padding-right: 20px;
`
const Header = s.div`
  text-align: center;
  border-bottom: 0.75pt solid #E5E5E6;
  margin-top: 20px;
  margin-bottom: 20px;
`
const Body = s.div`
  text-align: justify;
  color: black;
  margin-bottom: 20px;
`
const H1 = s.p`
  color: black;
  font-size: 2em;
  margin-bottom: 10px;
  font-weight: bold;
`
const H2 = s.p`
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`
const H3 = s.p`
  color: gray;
`
const Ul = s.ul`
  padding-left: 40px;
`
const Bullet = s.li`
  list-style-type: disc;
`
const Circle = s.li`
  list-style-type: circle;
`
const Square = s.li`
  list-style-type: square;
`
const None = s.li`
  list-style-type: none;
`

export default () => (
  <Box>
    <Header>
      <H1>Terms of Service</H1>
      <H3>Last updated 18/11/2019</H3>
    </Header>
    <Body>
      <H2>TERMS OF SERVICES of www.invitree.me</H2>
      <p>
        All terms and conditions in this document are applicable for your use of the website (or on any application on your mobile device) of Sprite Consulting Co., Ltd. (the “Company”), which includes www.invitree.me (the “Website”).
      </p>
      <p>
        Using the Website (or application on your mobile phone) constitutes your acknowledgment and acceptance of all of these terms of the Services. If you do not accept to be legally bound by the terms of any or all of the conditions stated herein, please do not use the Website (or application on your mobile phone).
      </p>
      <p>
        The Company reserves the sole right to amend or change the terms of use, policy or manual of the Website at the discretion of the Company. Any amendment will be effective immediately upon the announcement of the revised version of the Website and any right that may exist within the acknowledgement notice about editing changes shall be waived. Therefore, if you do not agree to such changes, please stop using the Website.
      </p>

      <H2>Registration and the User Account</H2>
      <p>
        The Company reserves the right to only Services to the User who registers to create an account on the Company's the Website. Such User will be able to purchase the items, and use the Services and activities that are provided in the Website, such as ordering products or through auction from the Websites, etc. When you create an account, you agree to the following:
      </p>
      <Ul>
        <Bullet>
          One User can register with one account only;
        </Bullet>
        <Bullet>
          You agree to provide detailed information that is required to be accurate and current;
        </Bullet>
        <Bullet>
          You agree to update your information regularly;
        </Bullet>
        <Bullet>
          You are not authorized to share your username and password or other account access information with any other party temporarily or permanently;
        </Bullet>
        <Bullet>
          You agree to notify the Company immediately of any unauthorized use of your username or password; and
        </Bullet>
        <Bullet>
          You are responsible for any actions arising through the use of your account and risks that may occur in every case, arising from access to your account without permission:
        </Bullet>

        <Ul>
          <Circle>
            For online members who are under 20 years, any transactions relating to finance, such as online purchases, or auctions, you should carefully review the terms of use under the guidance of your parents or legal guardian, and ensure that the parents or guardian understand the terms and conditions of the Website.
          </Circle>
          <Circle>
            The Company reserves the right, at its sole discretion, to revoke your membership immediately without prior notice, if the Company finds you have committed any of the following actions:
          </Circle>

          <Ul>
            <Square>
              You provide false information on registration, including not updating data information to be current.
            </Square>
            <Square>
              Discovery of usage of your account by another person.
            </Square>
            <Square>
              Any action through your account, which is a breach of the terms of use under the Terms of the Services and other conditions.
            </Square>
            <Square>
              Any actions that are committed through your account without abiding by the law or public morality, or are carried out fraudulently.
            </Square>
          </Ul>

          <Circle>
            The Company shall publish a document on the terms of use and disclosure of information about the registration and use of the Website based policy on personal information, which includes but is not limited to the disclosure of the Organizer (as defined below). You can consider such a document for more details.
          </Circle>
        </Ul>
      </Ul>

      <H2>Limitations of Use</H2>
      <p>
        In the use of this Website, you will proceed under the laws and regulations concerned, and agree to act as follows:
      </p>
      <Ul>
        <Bullet>
          Do not publish messages that harms a person's reputation;
        </Bullet>
        <Bullet>
          Do not publish unlawful, threatening, harassing, libelous, vulgar, obscene, pornographic, blasphemous, and cynical content;
        </Bullet>
        <Bullet>
          Do not incite rebellion;
        </Bullet>
        <Bullet>
          No commercial use without permission from the Company;
        </Bullet>
        <Bullet>
          Do not restrict anyone else from the Website without the permission of the Company;
        </Bullet>
        <Bullet>
          Do not publish links to the Website or any other content that is unlawful or inappropriate;
        </Bullet>
        <Bullet>
          Do not publish spam, direct marketing communication, advertising media, advertising sales support or persuasion without permission, or trading in other styles; and
        </Bullet>
        <Bullet>
          Do not publish viruses, spyware, Trojans or any other program to hide malicious files, causing damage to the Website or the User.
        </Bullet>
      </Ul>

      <H2>Terms of Purchase</H2>
      <p>
        You acknowledge and manifestly agree that the Website is intended provide you with a quick, easy and efficient Services to access items, including tickets and e-commerce activities. The Company is not the organizer for selling goods or the operator of any show or such goods whatsoever. Therefore, when you purchase any product through this Website, you have agreed to the following terms:
      </p>
      <Ul>
        <Bullet>Purchasing</Bullet>
        <Ul>
          <Circle>
            The Company and this Website are intermediaries in the sale of goods, which include tickets of shows from the organizers (the “Organizer”) or events, and provide the payment system for the Organizer only. Your purchasing order through this Website is for the product from the Organizer;
          </Circle>
          <Circle>
            When the order is confirmed, this means that the transactions conducted through the Website have been completed, which refers to the fact that you have paid for the products/tickets including the fees relating to the Company. The Company reserves the right to refund or cancel the transaction, including in the case that the User was to make a mistaken transaction. Although the Company confirms transactions through various channels, such as the Websites or by e-mail etc., the Company shall be deemed that the transaction will be effective upon confirmation via the Website;
          </Circle>
          <Circle>
            The Company is not involved in pricing in all cases. The Organizer will determine the price of products, discounts, or promotions (if applicable); and
          </Circle>
          <Circle>
            The Company is not involved in determining the amount of goods to be sold through the Website in all cases. In addition, in some cases, the Organizer may arrange to distribute the products/tickets through various channels including the Websites, so the Company cannot confirm the number of items that can be sold through the Website. The amount of the products/tickets on the Website is defined by the Organizer only.
          </Circle>
        </Ul>

        <Bullet>Payment Method</Bullet>
        <Ul>
          <Circle>
            You can make payment via the methods posted on the Company’s the Website; and
          </Circle>
          <Circle>
            The payment is completed, provided that the payment has been confirmed via the Website.
          </Circle>
        </Ul>

        <Bullet>The Services fees</Bullet>
        <Ul>
          <Circle>
            The User agrees that when the it places an order of any type on the Company's the Website, the Company shall have the right to charge a the Services fee and other related charges for the Services rendered according to the activity, in which the fees are subject to change depending on the Services type; and
          </Circle>
          <Circle>
            The Services fees are non-refundable under any conditions.
          </Circle>
        </Ul>

        <Bullet>Postponement and cancellation of activity</Bullet>
        <Ul>
          <Circle>
            The User agrees that there may potentially be postponement or cancellation of the activity, in which such postponement or cancellation shall be decided by the Organizer. The Company will not take part in any consideration of the decision process whatsoever; and
          </Circle>
          <Circle>
            Should there be postponement or cancellation of the activity, please directly follow up with the news and/or notification from the Organizer. The Company may convey information regarding postponement or cancellation of the event via the Company’s the Website.
          </Circle>
        </Ul>

        <Bullet>Refund eligibility</Bullet>
        <Ul>
          <Circle>
            In the case of postponement or cancellation of any activity, and if you are eligible for a refund, you agree to contact the Organizer to be only party who is responsible for your refund, except a case that the Company deems as a special case whereby you shall be eligible for the refund from that website’s event only. For more information, please contact us via email <a href='mailto:customerservice@invitree.me' style={{'text-decoration': 'underline', 'color': 'blue'}}>customerservice@invitree.me</a> or contact information below; and
          </Circle>
          <Circle>
            The Company shall not be liable for any damages caused by postponement or cancellation of the activity by the event organizer in any conditions.
          </Circle>
        </Ul>

        <Bullet>
          Responsibility for the final product’s content or characteristics
        </Bullet>
        <Ul>
          <Circle>
            The Company shall not take part in any aspect of the organization of any activity. Should you find that the content or characteristics of the product or activity do not meet with the purpose of your purchase, you agree to directly inform the Organizer and that you forego and/or withhold any request you may have with the Company.
          </Circle>
        </Ul>
      </Ul>

      <H2>Limitation of the Company’s Liability</H2>
      <Ul>
        <Bullet>
          You acknowledge and manifestly agree that the Company is not responsible for any mistakes in the content (i.e. messages, photos, trademarks, information, and /or any other aspects) on the Company’s the Website that may result from out-of-date information or spelling mistakes arising from human errors of the staff of the Company or details acquired from the Organizer. The Company reserves the right to amend the content on the Website without prior notice.
        </Bullet>
        <Bullet>
          The Company shall not be held responsible for the content and information on the Website, www.invitree.me, or for the accuracy, completeness, or benefits of the Company, which may be caused by a third party, or any User, including opinions and recommendations of the staff under the care of the Company.
        </Bullet>
        <Bullet>
          You acknowledge and manifestly agree that the Company admits no responsibility toward any the User’s inability to use the Services (in any aspect) of the Website.
        </Bullet>
        <Bullet>
          You agree to be responsible for any risk that may arise from choosing the device to use the Services from the Website such as mobile phones, computer, hardware, and other items including fees for the Services rendered.
        </Bullet>
        <Bullet>
          The media and content on the Company’s the Website may be inaccurate due to a technical fault or misspelling. Furthermore, providing media, information, and the Services on the Website will be in a “as is” manner with no condition, guarantee, or any type of disclaimer. Therefore, the Company refuses to guarantee the conditions of any product, whether explicitly or implicitly, in its entirety. This includes, but is not limited to, implied warranties or conditions suitable for commercial purposes by default, satisfactory quality, suitability of the purpose or the need to use for specific purpose, rightful ownership, and not infringing with respect to the Website and its content and media on the Website. The use of the Website shall not cause disruption and the use of the Website is subject to the laws imposed on you or the sending of your personal information which pertains to the Website shall be successful, correct, and delivered safely. Hence, any rejections mentioned above are subject to the extent permitted by law.
        </Bullet>
        <Bullet>
          As far as the applicable law permits, the Company and its officers, directors, shareholders, employees, or representatives of the Company shall not be liable for direct, indirect, punitive or consequential damages, or any other damages of any kind. This includes, but is not limited to, loss of revenue, profits, goodwill, data, contracts, use of money, losses or damages caused by or relating in any way to business interruption, whether in tort of (including, but not limited to, negligence) contract or otherwise, as a result of, or relating to the use of, or inability to use the Website, its content or materials on the Website or accessed through the Website, including, but not limited to, any damages as a consequence of, or in relation to the use of reliable information received from the Website, or from the Organizers, or that which results from mistakes, omissions, interruptions, deletion of files or emails, errors, defects, viruses, delays in operation or transmission, or the inability to work on anything whether as a result of an event of force majeure, failure in communication, theft, destruction or unauthorized access of the Company’s internal records, programs, and/or the Services.
        </Bullet>
      </Ul>

      <H2>Liability Agreement</H2>
      <Ul>
        <Bullet>
          You agree to protect, indemnify, and defend the Company and its executives, directors, employees and representatives of the Company from the demands of compensation, debts, liabilities and expenses of any kind (including, but not limited to, reasonable attorney’s fees) that result from, or are relating to (1) your usage of the Company’s the Website (2) breach of the Company’s terms and conditions, (3) breach of the rights of other users or (4) any action committed by you in relation to the Company’s the Website.
        </Bullet>
      </Ul>

      <H2>Rules on Intellectual Property Rights</H2>
      <Ul>
        <Bullet>
          All content displayed on the Website shall be subject to the law on Intellectual Property whereby the Company or third parties allow companies to use their Intellectual Property. The content is to be used on the Website only. The Company prohibits any duplication of the said content for other purposes.
        </Bullet>
        <Bullet>
          The Company reserves all Intellectual Property Rights explicitly and originally to www.invitree.me in terms of content, symbols, fonts, graphics, design, and other related content and information. By using this Website, you agree not to duplicate, download, distribute, or disseminate any part of the Website other than for personal usage. You shall not imitate any part of this the Website, in any form, unless you have received written permission to do so from the Company. The Company is not responsible for any mistakes in content caused by breach of Intellectual Property Rights or damage arising from the User, no matter what the circumstances may be.
        </Bullet>
        <Bullet>
          It is prohibited for the User to upload content in order make changes to the data or any part of the Website, www.invitree.me, without written permission from the Company. Violators are liable for such action. Furthermore, information distributed on the Website, www.invitree.me, shall belong to the Company indefinitely and cannot be claimed with reasons, exceptions, or raise any legal clauses to justify your claim. The Company can do as it pleases with that information or media. The User agrees that the Company holds the right to modify, adapt, reproduce, publish, distribute data and information the Services on the Website www.www.invitree.me.me which the User owns.
        </Bullet>
      </Ul>

      <H2>Connecting to the Website</H2>
      <Ul>
        <Bullet>
          You acknowledge and manifestly agree that in the case of any implementation of links from other websites to the Website www.invitree.me, whether for commercial purposes or not, the Company shall not be affiliated with such links and shall not be responsible for any mistakes or damages which may arise from the use of the Website via third-party links, no matter what the circumstances may be.
        </Bullet>
        <Bullet>
          The Website, www.invitree.me, contains links to other websites which are not under the supervision of the Company. Therefore, the Company waives any responsibility for any consequences arising from or activity on those sites, both directly and indirectly.
        </Bullet>
      </Ul>

      <H2>Suspension, Modification, Amendment of the Website</H2>
      <Ul>
        <Bullet>
          The Company reserves the right to modify, temporarily or permanently discontinue the Website, including parts of the Website, without prior notification. You agree and accept that the Company shall not be liable for any loss or damage arising from any suspension, modification, or termination of the Services or any part of the Website.
        </Bullet>
      </Ul>

      <H2>Severability</H2>
      <Ul>
        <Bullet>
          If any clause in these terms and conditions is deemed unlawful, void, or unenforceable for any particular reason, such clause shall be considered separate from these Terms of Services and this shall not affect the integrity and the effectiveness of the rest of these Terms of Services.
        </Bullet>
      </Ul>

      <H2>Contact Us</H2>
      <Ul>
        <Bullet>
          For further information, questions or comments regarding our privacy policy please contact us at :
        </Bullet>
        <None>Sprite Consulting Co., Ltd.(Head office)</None>
        <None>Tax ID: 0105560080925</None>
        <None>239/2 Lumpini 1 Building Room 52, 5th Floor,</None>
        <None>Ratchadamri Road Lumpini, Pathumwan,</None>
        <None>Bangkok 10330</None>
        <None>Tel. 02-057 9135</None>
      </Ul>
    </Body>
  </Box>
)
