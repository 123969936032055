import React from 'react'
import s from 'styled-components'

const Box = s.div`
  padding-left: 20px;
  padding-right: 20px;
`
const Header = s.div`
  text-align: center;
  border-bottom: 0.75pt solid #E5E5E6;
  margin-top: 20px;
  margin-bottom: 20px;
`
const Body = s.div`
  text-align: justify;
  color: black;
  margin-bottom: 20px;
`
const H1 = s.p`
  color: black;
  font-size: 2em;
  margin-bottom: 10px;
  font-weight: bold;
`
const H2 = s.p`
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`
const H3 = s.p`
  color: gray;
`
const Ul = s.ul`
  padding-left: 40px;
`
const Bullet = s.li`
  list-style-type: disc;
`

export default () => (
  <Box>
    <Header>
      <H1>Privacy Policy</H1>
      <H3>Last updated 14/03/2019</H3>
    </Header>
    <Body>
      <H2>Introduction</H2>
      <p>
        Sprite Consulting Company Limited ("Company", "Invitree") and
        www.invitree.me ("Website") understands any information that identifies
        the customer to whom such information pertains is of utmost importance.
        Company, therefore, has established the following privacy policy for its
        handling and protection of personal information.
      </p>

      <H2>Personal Information Collection and Usage</H2>

      <p>
        When you use, services provided on www. invitree.me you will be asked to
        provide personal information such as name, contact address, e-mail,
        telephone number, Bank account number or credit card details, etc. and
        including, but not limited to, users' behavior. Invitree generally
        collect this information for the following purposes:
      </p>

      <Ul>
        <Bullet>
          To facilitate your use of the Services (as defined in the Terms of
          Use) and/or access to the website;
        </Bullet>
        <Bullet>
          To facilitate your use of the Services by communicating via phone
          call, email or text message on the usage of the Service of the Website
          or any activities from another third-parties’ website to acknowledge
          or answer the questions.
        </Bullet>
        <Bullet>
          To deliver the sales promotion to the target market from Personal
          Information Collection and Non-Personal Information Collection through
          the platform, from the time to use the platform and product categories
          or any of benefits from the Website.
        </Bullet>
        <Bullet>
          To deliver the Sales Offer to the target market from Personal
          Information Collection.
        </Bullet>
      </Ul>

      <H2>Disclosure of Personal Information</H2>

      <Ul>
        <Bullet>
          The Company will not disclose your personal identification information
          to any third parties without your prior consent.
        </Bullet>
        <Bullet>
          The Company may share only the personal information that is relevant
          to the job or the event for the Organizer or the Manager of the event.
        </Bullet>
        <Bullet>
          The Company may share our third-party service providers, including but
          not limited to those who provide the credit card processor, payment,
          or the e-mail service provider etc.
        </Bullet>
        <Bullet>
          The Company will not reveal your personal information to any third
          party unless required under law or by any government enforcement
          agency or to protect the rights of property and the safety of the
          Company employees, agents, and other web site users etc.
        </Bullet>
        <Bullet>
          In case of transfer of business mergers and acquisitions, asset sales
          or loans. The Company may be required to disclose some information to
          those involved in such transactions accordingly.
        </Bullet>
      </Ul>

      <H2>Modification to Privacy and Disclaimer</H2>

      <p>
        Company reserves the right to change this Privacy Policy and disclaimer
        at any time. The content presented on this page is considered the latest
        version. Should any modification to our Privacy Policy occur, we will
        post the updated version on the page so that you are always aware of how
        we safeguard your personal data.
      </p>

      <H2>Security</H2>
      <p>
        The Company has implemented the appropriate technical, physical security
        management and loss prevention using disproportionate access the data
        without permission to disclosure or adjustment change personal
        information.
      </p>
      <p>
        However, The Company reserves the liability protection of such security
        as there is not a single system or public network that can guarantee to
        be completely and thoroughly secured.
      </p>
    </Body>
  </Box>
)
