import React from 'react'
import s from 'styled-components'

import DateTimePicker from './datetime-picker'

const tempOnChange = value => value

const PhoneInput = s.div`
  border: 1px solid #887979;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #000000;
  
  & > span {
    flex-basis: 30px;
  }
  & > input {
    flex-grow: 1;
  }
`
const ddlStyle = `
  -webkit-appearance: none;
  -moz-appearance : none;
  border: 1px solid #887979;
  border-radius: 10px;
  padding: 15px 20px;
  background: none;
  font-size: 14px;
  
  &:disabled{
    color: #bbbbbb;
    background-color: #efefef;
    cursor: no-drop;
  }
`
const Select = s.select`width: 100%; ${ddlStyle}`

const reg = new RegExp('^[0-9]+$')
const fnPhoneNumber = {
  split: value => (value.split('+66')[1] ? value.split('+66')[1] : value),
  onChange: emit => e => {
    const value = e.currentTarget.value
    if (reg.test(value)) {
      emit(value)
    } else {
      e.currentTarget.value = value.substr(0, value.length - 1)
    }
  },
}

const styleLabel = { color: '#887979', fontWeight: 'bold' }

const Input = {
  Textbox: ({
    title,
    placeholder,
    type = 'text',
    disabled = false,
    defaultValue = '',
    onChange = tempOnChange,
  }) => (
    <div className="form-section">
      <label style={styleLabel}>{title}</label>
      <input
        type={type}
        disabled={disabled}
        autoComplete="off"
        className="form-textbox"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={e => onChange(e.currentTarget.value)}
      />
    </div>
  ),
  Textbox2: ({
    title,
    placeholder,
    type = 'text',
    disabled = false,
    value = '',
    onChange = tempOnChange,
  }) => (
    <div className="form-section">
      <label style={styleLabel}>{title}</label>
      <input
        type={type}
        disabled={disabled}
        autoComplete="off"
        className="form-textbox"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
      />
    </div>
  ),
  Textarea2: ({
    title,
    placeholder,
    type = 'text',
    disabled = false,
    value = '',
    onChange = tempOnChange,
  }) => (
    <div className="form-section">
      <label style={styleLabel}>{title}</label>
      <textarea
        type={type}
        disabled={disabled}
        className="form-textbox"
        placeholder={placeholder}
        value={value}
        rows="4"
        onChange={e => onChange(e.currentTarget.value)}
      />
    </div>
  ),
  Textarea3: ({
    title,
    placeholder,
    type = 'text',
    disabled = {disabled},
    value = '',
    onChange = tempOnChange,
  }) => (
    <div className="form-section">
      <label style={styleLabel}>{title}</label>
      <textarea
        type={type}
        disabled={disabled}
        className="form-textbox"
        placeholder={placeholder}
        value={value}
        rows="4"
        onChange={e => onChange(e.currentTarget.value)}
      />
    </div>
  ),
  Dropdown: ({
    title,
    data = [],
    type = 'text',
    disabled = false,
    defaultValue = '',
    onChange = tempOnChange,
  }) => (
    <div className="form-section">
      <label style={styleLabel}>{title}</label>
      <Select
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={e => onChange(e.currentTarget.value)}
      >
        {data.map((v, i) => (
          <option value={v.id} key={`option-${i}`}>
            {v.title}
          </option>
        ))}
      </Select>
    </div>
  ),
  Dropdown2: ({
    title,
    data = [],
    type = 'text',
    disabled = false,
    value = '',
    onChange = tempOnChange,
  }) => (
    <div className="form-section">
      <label style={styleLabel}>{title}</label>
      <Select
        disabled={disabled}
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
      >
        {data.map((v, i) => (
          <option value={v.id} key={`option-${i}`}>
            {v.title}
          </option>
        ))}
      </Select>
    </div>
  ),
  PhoneNumber: ({
    title,
    name,
    placeholder,
    type = 'text',
    disabled = false,
    defaultValue = '',
    onChange = tempOnChange,
  }) => (
    <div className="form-section">
      <label style={styleLabel}>{title}</label>
      <PhoneInput>
        <span>+66</span>
        <input
          type="text"
          name={name}
          disabled={disabled}
          autoComplete="off"
          className="form-textbox"
          placeholder={placeholder}
          defaultValue={fnPhoneNumber.split(defaultValue)}
          pattern="[0-9]{10}"
          onChange={fnPhoneNumber.onChange(onChange)}
        />
      </PhoneInput>
    </div>
  ),
  DateTimePicker,
  CheckBox: class extends React.Component {
    render() {
      return (
        <div className="form-section-agree">
          <div className="x-checkbox active" onClick={this.props.onChange}>
            {this.props.value ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa" style={{ width: 14, height: 14 }} />
            )}
          </div>
          {this.props.children}
        </div>
      )
    }
  },
}

export default Input
