import React, { Component } from 'react'

export default class SignInWithApple extends Component {
  componentDidMount() {
    window.AppleID.auth.init({
      clientId: 'me.invitree',
      scope: 'name email',
      redirectURI: 'https://apple.armm.wtf/callback',
      // state: '[STATE]'
    })
  }

  render() {
    return (
      <div>
        {/* <div
          id="appleid-signin"
          className="signin-button"
          data-color="black"
          data-border="true"
          data-type="sign in"
        /> */}
      </div>
    )
  }
}
