import React from 'react'
import { navigate } from 'gatsby'
import ModalHandler from '../../utils/modal'
import { signOut } from '../../utils/auth-utils'

/* eslint-disable no-undef */
function clickToggleMenu() {
  const id = '#menu-mobile-box'
  if ($(id).hasClass('active')) {
    setTimeout(() => {
      $('#menu-mobile-box>.menu-mobile-box__list').toggleClass('active')
      setTimeout(() => {
        $(id).toggleClass('active')
      }, 200)
    }, 50)
  } else {
    setTimeout(() => {
      $('#menu-mobile-box>.menu-mobile-box__list').toggleClass('active')
    }, 50)
    $(id).toggleClass('active')

    $('.menu-mobile-box.active').css('height', $(document).height())
  }
}

function clickPriceTable() {
  $('html, body').animate({ scrollTop: $('#price-table').offset().top }, 500)
  clickToggleMenu()
}

function clickSignIn() {
  ModalHandler.openModal('modalSignIn')
  clickToggleMenu()
}

function clickSignUp() {
  ModalHandler.openModal('modalSignUp')
  clickToggleMenu()
}

function clickChangePassword() {
  ModalHandler.openModal('modalChangePassword')
  clickToggleMenu()
}

async function clickEditProfile(cb) {
  clickToggleMenu()
  setTimeout(() => {
    cb()
  }, 100)
}

async function onSignOut() {
  try {
    signOut()
    navigate('/')
  } catch (e) {
    console.log(e)
  }
}

export default props => (
  <div className="navbar-header">
    <button className="navbar-toggler" type="button" onClick={clickToggleMenu}>
      <i className="icon-bar" />
      <i className="icon-bar" />
      <i className="icon-bar" />
    </button>
    <div className="menu-mobile-box" id="menu-mobile-box">
      <div onClick={clickToggleMenu} />
      <ul className="menu-mobile-box__list">
        {props.auth ? (
          <React.Fragment>
            {props.clubjoined.joined == true ? null :
              (
                <li className="list-item" onClick={() => navigate('/home')}>
                  {props.locale.HOME}
                </li>
              )}
            {props.clubjoined.joined == true ? null :
              (
                <li className="list-item-group">
                  <span onClick={() => navigate('/my-events')}>
                    {props.locale.MY_EVENT}
                  </span>
                  <ul className="menu-mobile-box__list">
                    <li className="list-item" onClick={() => navigate('/upcoming')}>
                      - {props.locale.UPCOMING}
                    </li>
                    <li className="list-item" onClick={() => navigate('/joined')}>
                      - {props.locale.JOINED}
                    </li>
                    <li className="list-item" onClick={() => navigate('/history')}>
                      - {props.locale.HISTORY}
                    </li>
                    <li
                      className="list-item"
                      onClick={() => navigate('/public-list')}
                    >
                      - {props.locale.PUBLIC}
                    </li>
                  </ul>
                </li>
              )}
            {props.clubjoined.joined == true ? null :
              (
                <li className="list-item" onClick={() => navigate('/club-list')}>
                  {props.locale.MY_CLUB}
                </li>
              )}
            {props.clubjoined.joined == true ?
              (
                <ul className="menu-mobile-box__list">
                  {props.lang === 'EN' ? (
                    <li
                      className="list-item"
                      onClick={() => props.onChangeLanguage('TH')}
                    >
                      - ไทย
                    </li>
                  ) : (
                      <li
                        className="list-item"
                        onClick={() => props.onChangeLanguage('EN')}
                      >
                        - English
                      </li>
                    )}
                </ul>
              ) :
              (
                <li className="list-item-group">
                  {props.locale.ACCOUNT}
                  <ul className="menu-mobile-box__list">
                    <li
                      className="list-item"
                      onClick={() => clickEditProfile(props.onOpenEditProfile)}
                    >
                      - {props.locale.EDIT_PROFILE}
                    </li>
                    <li className="list-item" onClick={clickChangePassword}>
                      - {props.locale.CHANGE_PASSWORD}
                    </li>
                    <li
                      className="list-item"
                      onClick={() => navigate('/payment/history')}
                    >
                      - {props.locale.PAYMENT_HISTORY}
                    </li>
                    {props.lang === 'EN' ? (
                      <li
                        className="list-item"
                        onClick={() => props.onChangeLanguage('TH')}
                      >
                        - ไทย
                      </li>
                    ) : (
                        <li
                          className="list-item"
                          onClick={() => props.onChangeLanguage('EN')}
                        >
                          - English
                        </li>
                      )}
                    <li className="list-item" onClick={onSignOut}>
                      - {props.locale.SIGN_OUT}
                    </li>
                  </ul>
                </li>
              )}
          </React.Fragment>
        ) : (
            <React.Fragment>
              <li className="list-item" onClick={clickToggleMenu}>
                {props.locale.HOME}
              </li>
              <li className="list-item" onClick={clickPriceTable}>
                {props.locale.PRICING}
              </li>
              <li className="list-item" onClick={() => navigate('/club-list')}>
                {props.locale.MY_CLUB}
              </li>
              <li className="list-item" onClick={clickSignIn}>
                {props.locale.SIGN_IN}
              </li>
              <li className="list-item" onClick={clickSignUp}>
                {props.locale.SIGN_UP}
              </li>
              {props.lang === 'EN' ? (
                <li
                  className="list-item"
                  onClick={() => props.onChangeLanguage('TH')}
                >
                  ไทย
                </li>
              ) : (
                  <li
                    className="list-item"
                    onClick={() => props.onChangeLanguage('EN')}
                  >
                    English
                  </li>
                )}
            </React.Fragment>
          )}
      </ul>
    </div>
  </div>
)
