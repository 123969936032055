import React, { useState } from 'react'
import Modal from 'react-modal'
import Input from '../../form/input'
import IconSuccess from '../../../images/ic_correct.svg'
import IconClose from '../../../images/ic_close.svg'
import IconWarning from '../../../images/icons/ic_warning.png'

const PopUpInformationModal = props => {
    const style1 = {textAlign: "left", padding: "0px", marginTop: "30px"}
    const { isOpen, locale, handlepopUpInformation, resultToDisplay, informationType, informationOnly, information } = props
    if (informationOnly == true) {
        return (
            <Modal
                isOpen={isOpen}
                className="information__modal"
                overlayClassName="information__modal__overlay"
            >
                <img
                    src={IconClose}
                    alt=""
                    className="information__modal__close"
                    onClick={handlepopUpInformation}
                />
                <div style={style1} className="content__modal">
                    <div style={style1} className="box__input__modal">
                    <Input.Textarea3
                        title={locale.ADDITIONAL_INFORMATION_CLUB_TITLE}
                        value={information}
                        disable={true}
                    />
                    </div>
                </div>
            </Modal>
        )
    } else {
        return (
            <Modal
                isOpen={isOpen}
                className="information__modal"
                overlayClassName="information__modal__overlay"
            >
                <img
                    src={IconClose}
                    alt=""
                    className="information__modal__close"
                    onClick={handlepopUpInformation}
                />
                <div className="content__modal">
                    <div className="title__modal" style={{ marginTop: '40px' }}>
                        {(informationType == 'positive') ? <img src={IconSuccess} alt="Success" /> : <img src={IconWarning} alt="Warning" />}
                    </div>
                    <div className="box__input__modal">
                        {locale[`${resultToDisplay}`]}
                    </div>
                </div>
                <div className="flex">
                    <div>
                        <button className="popup-confirm-delete" onClick={handlepopUpInformation}>
                            {locale['OK']}
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default PopUpInformationModal
