import React from 'react'
import IconClose from '../../images/ic_close.svg'
import TermEN from '../term-and-policy/term-en'
import TermTH from '../term-and-policy/term-th'
import PolicyTH from '../term-and-policy/policy-th'
import PolicyEN from '../term-and-policy/policy-en'



const TermAndConditionPopup = props => {
    const style1 = { position: "absolute", display: "flex", zIndex: "999", overflow: "hidden", height: "100vh", top: `${window.pageYOffset}px` }
    const style2 = { backgroundColor: "white", width: "90%", height: "90%", overflow: "auto", marginLeft: "auto", marginRight: "auto"}
    const style3 = { position: "sticky", float: "right" }
    const style4 = { height: "40px" }
    const { closeTermAndConditionPopup, ui, lang } = props
    if (ui == 'TERM') {
        return (
            <div className="modal" style={style1}>
                <div style={style2}>
                    <div style={style4}>
                        <img
                            style={style3}
                            src={IconClose}
                            alt=""
                            className="information__modal__close"
                            onClick={closeTermAndConditionPopup}
                        />
                    </div>
                    {lang === 'TH' ? <TermTH /> : <TermEN />}
                </div>
            </div>
        )
    } else if (ui == 'POLICY') {
        return (
            <div className="modal" style={style1}>
                <div style={style2}>
                    <div style={style4}>
                        <img
                            style={style3}
                            src={IconClose}
                            alt=""
                            className="information__modal__close"
                            onClick={closeTermAndConditionPopup}
                        />
                    </div>
                    {lang === 'TH' ? <PolicyTH /> : <PolicyEN />}
                </div>
            </div>

        )
    } else return null
}

export default TermAndConditionPopup

