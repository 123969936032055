import React from 'react'
import s from 'styled-components'

const ButtonFacebookLogin = s.button`
    background: #3B5998;
    border: 1px solid #3B5998;
    color:#ffffff;
    font-size: 13px;
    font-weight: unset;
    font-family: "SF Compact Display Medium", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    cursor: pointer;
    border-radius: 10px !important;
    padding: 10px;
    width: 200px;
    height: 43px;
    & > i {
      width: 25px;
      height: 25px;
      float: left;
      padding-top: 2px;
      font-size: 19px;
    }
`

const ButtonLineLogin = s.button`
    background: #1bb71f;
    border: 1px solid #1bb71f;
    color:#ffffff;
    font-size: 13px;
    font-weight: unset;
    font-family: "SF Compact Display Medium", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    cursor: pointer;
    border-radius: 10px !important;
    padding: 10px;
    width: 200px;
    height: 43px;
    & > img {
      width: 25px;
      height: 25px;
      float: left;
    }
`

const Button3 = s.button`
  cursor: pointer;
  background-color: #ffffff;
  color: #892a24;
  border-radius: 60px;
  border: 1px solid #892a24;
  padding: 5px 11px;
  font-size: 11px;
  width: 35px !important;
  height: 35px !important;
`

const Button = {
  Button1: ({ onClick, disabled = false, children }) => (
    <button
      className={`btn-1 ${disabled ? 'btn-disabled' : ''}`}
      onClick={onClick}
    >
      {children}
    </button>
  ),
  Button2: ({
    onClick,
    disabled = false,
    children,
    icon,
    style,
    noIcon = false,
  }) => (
    <button
      className={`btn-2 ${disabled ? 'btn-disabled' : ''}`}
      onClick={onClick}
      style={style}
    >
      {!noIcon && <i className={icon} style={{ marginRight: 10 }} />} {children}
    </button>
  ),
  Button3: ({ onClick, disabled = false, icon, style = null }) => (
    <Button3
      className={`${disabled ? 'btn-disabled' : ''}`}
      style={style}
      onClick={onClick}
    >
      <i className={icon} />
    </Button3>
  ),
  Button4: ({ onClick, disabled = false, children, style }) => (
    <button
      className={`btn-4 ${disabled ? 'btn-disabled' : ''}`}
      disabled={disabled}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  ),
  Button5: ({ onClick, disabled = false, children, style }) => (
    <button
      className={`btn-5 ${disabled ? 'btn-disabled' : ''}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  ),
  Button6: ({ onClick, disabled = false, children, style }) => (
    <button
      className={`btn-6 ${disabled ? 'btn-disabled' : ''}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  ),
  Button7: ({ onClick, disabled = false, children, style }) => (
    <button
      className={`btn-7 ${disabled ? 'btn-disabled' : ''}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  ),
  Button8: ({ onClick, disabled = false, children, style }) => (
    <button
      className={`btn-8 ${disabled ? 'btn-disabled' : ''}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  ),
  ButtonFacebookSignIn: ({ onClick, disabled = false, children }) => (
    <ButtonFacebookLogin
      className={`${disabled ? 'btn-disabled' : ''}`}
      onClick={onClick}
    >
      <i className="fab fa-facebook-f" /> {children}
    </ButtonFacebookLogin>
  ),
  ButtonLineLogin: ({ onClick, disabled = false, children }) => (
    <ButtonLineLogin
      className={`${disabled ? 'btn-disabled' : ''}`}
      onClick={onClick}
    >
      {children}
    </ButtonLineLogin>
  ),
}

export default Button
