import React from 'react'
import s from 'styled-components'
import { DatePicker } from 'antd'
import moment from 'moment'

import IconInvitations from '../../../images/icon-invitations.png'
import IconTransactions from '../../../images/icon-transactions.png'

import 'antd/lib/date-picker/style/css'

const { RangePicker } = DatePicker

export const PCWrapper = s.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 5px auto 5px auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  
  & > div {
   text-align: center;
   padding: 10px;
   // border: 1px solid red;
  }
  
  @media (max-width: 750px) {
    display: none;
  }
`
export const LogoImage = s.img`width: 70px;height: 70px;border-radius: 50px;cursor:pointer;box-shadow: 0 5px 7px rgba(0,0,0,.17);`
export const PcBoxSummary = s.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 500px;
  margin: 0 auto;
`
const PcTabs = s.div`
  cursor : pointer;
  display : inline-block;
  ${props => (props.isMobile ? 'width:50%' : 'margin-right: 20px;')}
  padding: 10px;
  ${props => (props.active ? 'border-bottom: 4px solid #C14C40;' : null)}
`
const PcIcon = s.img`width: 20px;height: 20px;margin-right: 10px;`
export const PcBoxWrapperSearch = s.div`
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 10px 180px;
    grid-template-columns: 1fr 180px;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    
    & > div:nth-child(1) {
      text-align: left;
    }
    
    & > div:nth-child(2) {
      text-align: right;
    }
`

export const stylePcButtonNewInvitation = {
  width: '80%',
  maxWidth: '200px',
  border: '1px solid #892a24',
  borderRadius: '12px',
}

const BoxSearchLeft = s.div`
  display: inline-block;
  padding: 10px;
  border:1px solid #887979;
  border-radius: 10px 0 0 10px;
  background: #887979;
  
  & > select {
    color :#ffffff;
    background: none;
  }

  select > option {
    color: #887979;
    background: none;
 }
`
const BoxSearchRight = s.div`
  display: inline-block;
  padding: ${({ isCreated }) => (isCreated ? '5px 10px' : '10px')};
  border:1px solid #887979;
  border-radius: 0 10px 10px 0;
  
  & > input {
    width: 250px;
  }
  
  & > button {
    cursor:pointer;
    color: #887979;
    background: none;
  }
  
  & .ant-calendar-picker-input {
    border : none;
  }
`

const BoxSearchRight2 = s.div`
  display: inline-block;
  padding: ${({ isCreated }) => (isCreated ? '5px 10px' : '10px')};
  border:1px solid #887979;
  border-radius: 10px;
  
  & > input {
    width: 250px;
  }
  
  & > button {
    cursor:pointer;
    color: #887979;
    background: none;
  }
  
  & .ant-calendar-picker-input {
    border : none;
  }
`

export const SummaryBoxItems = s.div`
  width: 100%;
  display: table;
  text-align: center;
  background-color: #E8E8E8;
  padding: 20px 10px;
  & > div {
    display: table-cell;
    vertical-align: middle;
  }
  & > h5 { font-weight:bold; }
`

export const UpgradeBox = s.div`
  text-align: center;
  padding: 20px;
`
//=== Mobile ===//

export const MobileWrapper = s.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 50px 5px 150px 5px 90px;
  grid-template-rows: auto 150px 90px;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 1px solid #eaeaea;
  
  & > div {
   // border: 1px solid red;
  }
  
  @media (min-width: 750px) {
    display: none;
  }
`

export const MobileHeadTitle = s.div`
  text-align: center;
  padding: 15px;
  font-weight: bold;
`

export const MobileMoneyBox = s.div`
  background: #e8e8e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`
export const MobileMoneyTitle = s.div`
  font-size : 25px;
`

export const MobileBoxSummary = s.div`
  padding: 5px 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
`

const SelectDynamicComponent = s.select`
  background: none;
`

export const styleMobileButtonUpgrade = {
  width: '80%',
  maxWidth: '200px',
  padding: 10,
}
export const styleMobileButtonNewInvitation = {
  width: '80%',
  maxWidth: '200px',
  border: '1px solid #892a24',
  borderRadius: '12px',
  marginTop: 20,
}

function onDateRangeChange(onChange) {
  return value => {
    const form = moment(value[0]).format('YYYY-MM-DDT00:00:00')
    const to = moment(value[1]).format('YYYY-MM-DDT23:59:59')
    onChange({ form: form + '.00Z', to: to + '.00Z' })
  }
}

function dynamicComponents(props) {
  if (['status', 'payment_method'].indexOf(props.keywordType) > -1) {
    return (
      <SelectDynamicComponent value={props.keyword} onChange={props.onKeyword}>
        {props.dynamicSearch[props.keywordType].map((v, i) => (
          <option value={v.id} key={`Dropdown-dynamic-Components-row-${i}`}>
            {v.title}
          </option>
        ))}
      </SelectDynamicComponent>
    )
  } else if (['created_at', 'date'].indexOf(props.keywordType) > -1) {
    return (
      <RangePicker
        defaultValue={[
          moment(props.keyword.form, 'YYYY-MM-DD'),
          moment(props.keyword.to, 'YYYY-MM-DD'),
        ]}
        format={'YYYY-MM-DD'}
        onChange={onDateRangeChange(props.onKeyword)}
      />
    )
  } else {
    return null
  }
}

export function SearchForm(props) {
  const keyDynamic = props.dynamicSearch ? Object.keys(props.dynamicSearch) : []
  const isHasDynamic = keyDynamic.indexOf(props.keywordType) > -1
  const isCreated = ['created_at', 'date'].indexOf(props.keywordType) > -1

  return (
    <React.Fragment>
      <BoxSearchLeft>
        <select value={props.keywordType} onChange={props.onKeywordType}>
          {props.data.map((v, i) => (
            <option value={v.id} key={`Dropdown-search-row-${i}`}>
              {v.title}
            </option>
          ))}
        </select>
      </BoxSearchLeft>
      <BoxSearchRight isCreated={isCreated}>
        {isHasDynamic ? (
          dynamicComponents(props)
        ) : (
          <input
            type="text"
            className="form-control"
            placeholder="Search.."
            value={props.keyword}
            onChange={props.onKeyword}
          />
        )}
        <button onClick={props.onSearch}>
          <i className="fa fa-search" />
        </button>
      </BoxSearchRight>
    </React.Fragment>
  )
}

SearchForm.defaultProps = {
  onKeyword: null,
  onKeywordType: null,
  onSearch: e => e.preventDefault(),
}

export function SearchForm2(props) {
  const keyDynamic = props.dynamicSearch ? Object.keys(props.dynamicSearch) : []
  const isHasDynamic = keyDynamic.indexOf(props.keywordType) > -1
  const isCreated = ['created_at', 'date'].indexOf(props.keywordType) > -1

  return (
    <React.Fragment>
      <BoxSearchRight2 isCreated={isCreated}>
        {isHasDynamic ? (
          dynamicComponents(props)
        ) : (
          <input
            type="text"
            className="form-control"
            placeholder="Search.."
            value={props.keyword}
            onChange={props.onKeyword}
            onKeyPress={props.onKeyPress}
          />
        )}
        <button onClick={props.onSearch}>
          <i className="fa fa-search" />
        </button>
      </BoxSearchRight2>
    </React.Fragment>
  )
}

SearchForm2.defaultProps = {
  onKeyword: null,
  onKeywordType: null,
  onSearch: e => e.preventDefault(),
}

const initLocale = {
  INVITATIONS: '',
  TRANSACTIONS: '',
  TICKET_GOODS: '',
}

export function PcMenuTabs({ name, onClick, isMobile, locale = initLocale }) {
  return (
    <div style={{ textAlign: isMobile ? 'center' : 'left' }}>
      <PcTabs
        isMobile={isMobile}
        active={name === 'INVITATIONS'}
        onClick={onClick.bind(null, 1)}
      >
        <PcIcon src={IconInvitations} alt="" />{' '}
        {locale.INVITATIONS.toUpperCase()}
      </PcTabs>
      <PcTabs
        isMobile={isMobile}
        active={name === 'TRANSACTIONS'}
        onClick={onClick.bind(null, 2)}
      >
        <PcIcon src={IconTransactions} alt="" />{' '}
        {locale.TRANSACTIONS.toUpperCase()}
      </PcTabs>
      {/* Temp remove configure tab */}
      {/* <PcTabs
        isMobile={isMobile}
        active={name === 'TICKET_GOODS'}
        onClick={onClick.bind(null, 3)}
      >
        <PcIcon src={IconTransactions} alt="" />{' '}
        {locale.TICKET_GOODS.toUpperCase()}
      </PcTabs> */}
    </div>
  )
}
