import React from 'react'
import swal from 'sweetalert'

import Loading from '../loading'
import Input from '../form/input'
import Button from '../form/buttons'

import UploadLogo from '../upload/profile'
import { getBase64 } from '../../utils/string'
import { deleteProfileImageAPI, uploadProfileImageAPI } from '../../utils/api'
import { notificationWarning } from '../../utils/notification'

export default class ProfileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      avartar: '',
      email: '',
      fullName: '',
      phoneNumber: '',
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onUpload = this.onUpload.bind(this)
  }

  componentDidMount() {
    const { picture, name, phone_number = '', email } = this.props.data
    this.setState({
      avartar: picture,
      fullName: name,
      phoneNumber: phone_number,
      email,
    })
  }

  placeholderPhone = `888888888`

  placeholderFullName = `${this.props.locale.FULL_NAME.toLowerCase()}...`

  onSubmit() {
    let payload = { ...this.state }
    let phoneNumber = payload.phoneNumber

    if (phoneNumber.length > 0) {
      if (phoneNumber.length === 9) {
        if (phoneNumber.indexOf('+66') < 0) {
          payload.phoneNumber = '+66' + phoneNumber
        }
      } else {
        notificationWarning(
          this.props.locale.WARNING,
          this.props.locale.WARNING_INVALID_PHONE_NUMBER_INPUT
        )
      }
    }

    this.props.onSubmit(payload)
  }

  style1 = {
    textAlign: 'right',
    padding: 10,
    borderTop: '1px solid #E8E8E8',
    marginTop: 20,
  }
  style2 = { padding: 5 }

  async onUpload(e) {
    if (e.currentTarget.files[0]) {
      const data = await getBase64(e.currentTarget.files[0])
      await deleteProfileImageAPI()
      const res = await uploadProfileImageAPI({ data_url: data })
      if (res.code === 'OK') {
        swal(
          this.props.locale.SUCCESS,
          this.props.locale.WARNING_UPLOAD_SUCCESS,
          'success'
        )
        this.setState({ avartar: data })
      } else {
        swal(
          this.props.locale.ERROR,
          this.props.locale.WARNING_UPLOAD_FAIL,
          'error'
        )
      }
    }
  }

  valueAvartar = () =>
    this.state.avartar
      ? this.state.avartar
      : process.env.GATSBY_PLACEHOLDERS_IMAGE_USER_PROFILE

  onChangeFullName = fullName => this.setState({ fullName })

  onChangePhoneNumber = phoneNumber => this.setState({ phoneNumber })

  render() {
    if (!this.props.data) {
      return <Loading />
    }
    return (
      <div className="profile-box">
        <div className="profile-box__header">
          <h3>{this.props.locale.EDIT_PROFILE}</h3>
          <div className="close-box">
            <span className="close" onClick={this.props.onClose}>
              &times;
            </span>
          </div>
        </div>
        <UploadLogo
          noMarginTop
          id="input-upload-profile-avatar"
          email={this.state.email}
          value={this.valueAvartar()}
          onChange={this.onUpload}
        />
        <Input.Textbox
          title={this.props.locale.FULL_NAME}
          placeholder={this.placeholderFullName}
          defaultValue={this.state.fullName}
          onChange={this.onChangeFullName}
        />
        <Input.PhoneNumber
          title={this.props.locale.PHONE_NUMBER}
          name="phone"
          placeholder={this.placeholderPhone}
          defaultValue={this.state.phoneNumber}
          onChange={this.onChangePhoneNumber}
        />
        <div style={this.style1}>
          <div style={this.style2}>
            <Button.Button4 onClick={this.onSubmit}>
              {this.props.locale.SAVE}
            </Button.Button4>
          </div>
        </div>
      </div>
    )
  }
}
