import React from 'react'

import PropTypes from 'prop-types'
import Header from './template/header'
import Footer from './template/footer'
import './layout.css'

const Layout = ({ auth, children, clubRegister, EventType, selectBanner, defaultBanner, hideFooter = false }) => (
  <div className="page-wrapper">
    <Header auth={auth}
            EventType={EventType}
            SelectBanner={selectBanner}
            defaultBanner={defaultBanner}
            />
    {children}
    {(auth || window.location.pathname == '/KMITLEngineerAlumni')&& <Footer clubRegister={clubRegister || {}}/>}
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
