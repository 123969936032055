import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Button from '../form/buttons'
import Input from '../form/input'
import PopUpInformationModal from '../club/components/popupInformation-modal'
import { sendRequestToClub } from '../../utils/api'
import { ThemeProvider } from 'styled-components'


const style3 = { textAlign: 'center', padding: 10 }
const style4 = { padding: '7px 5px', marginTop: '30px' }
const style5 = { color: 'red', fontWeight: 'bold', width: '80%', maxWidth: 340, margin: '0 auto' }
const style6 = { textAlign: 'left', fontSize: '16px', fontWeight: 'bold', width: '80%', maxWidth: 340, margin: '15px auto 0px auto', color: '#887979' }
const style10 = { width: '100%', maxWidth: 400, margin: '0 auto' }
const style11 = { padding: '10px 10px 30px 0' }
const style12 = { padding: '5px' }

class RequestJoineClub extends Component {
    constructor(props) {
        super(props)
        this.state = {
            BannerTH: process.env.GATSBY_IMG_SIGN_IN_TH,
            BannerEN: process.env.GATSBY_IMG_SIGN_IN_EN,
            SelectClub: '',
            panelInfo: '',
            openInformationModal: false,
            requestToClubResult: 'string',
            informationType: '',
            email: '',
            additionalInfo: '',
            isEmailvalid: undefined,
            fullName: '',
            isNamevalid: false,
        }
        this.ConvertBanner = this.ConvertBanner.bind(this)
        this.splitName = this.splitName.bind(this)
        this.emailValidation = this.emailValidation.bind(this)
        this.nameValidation = this.nameValidation.bind(this)
        this.onSubmitRequestToClub = this.onSubmitRequestToClub.bind(this)
        this.handlepopUpInformation = this.handlepopUpInformation.bind(this)
    }
    componentDidMount() { this.ConvertBanner() }

    // Recieve SelectBanner from header.js or sign page to choose which clubbanner is displayed
    ConvertBanner() {
        const SelectBanner = this.props.SelectBanner
        switch (SelectBanner) {
            case 'Default':
                this.setState({
                    BannerTH: process.env.GATSBY_IMG_SIGN_UP_TH,
                    BannerEN: process.env.GATSBY_IMG_SIGN_IN_EN,
                    isJoineRequest: false,
                })
                break
            case 'KMITL':
                this.setState({
                    BannerTH: process.env.GATSBY_IMG_SIGN_IN_KMITL,
                    BannerEN: process.env.GATSBY_IMG_SIGN_IN_KMITL,
                    isJoineRequest: true,
                    SelectClub: 'KMITLEngineerAlumni',
                    panelInfo: this.props.locale.SIGNUP_INFO_KMITL,
                })
                break
        }
    }
    // split fullname in state and exteact to fistname and lastname
    splitName() {
        const fullnameToArray = (this.state.fullName.trim()).split(/\s+/)
        const returnName = {
            firstname: fullnameToArray[0],
            lastname: fullnameToArray.slice(1, fullnameToArray.length).join(" ")
        }
        return returnName
    }

    nameValidation(fullName) {
        if (fullName.length != 0) {
            this.setState({ fullName: fullName, isNamevalid: true })
        } else {
            this.setState({ isNamevalid: false })
        }
    }
    emailValidation(email) {
        if (
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            this.setState({ email: email, isEmailvalid: true })
        } else {
            this.setState({ isEmailvalid: false })
        }
    }

    setAdditionalInfo(additionalInfo) {
        this.setState({ additionalInfo: additionalInfo })
    }

    onSubmitRequestToClub = async () => {
        const getnameFromState = this.splitName()
        if (getnameFromState.lastname !== "") {
            const sendRequestToClubPayload = {
                email: this.state.email,
                firstname: getnameFromState.firstname,
                lastname: getnameFromState.lastname,
                additional_info: this.state.additionalInfo
            }
            const sendRequestResult = await sendRequestToClub(this.state.SelectClub, JSON.stringify(sendRequestToClubPayload))

            if (sendRequestResult.code === 'OK') {
                this.setState({ requestToClubResult: 'REQUEST_TO_CLUB_SUCCESS', informationType: 'positive' })
                this.handlepopUpInformation()
                this.props.onClose()
            } else {
                alert(sendRequestResult.text.error)
                this.props.onClose()
            }
        } else {
            this.setState({ requestToClubResult: 'REQUEST_TO_CLUB_NO_SURNAME', informationType: 'negative' }, this.handlepopUpInformation())
        }
    }

    handlepopUpInformation = () => {
        this.setState({ openInformationModal: !this.state.openInformationModal })
    }

    render() {
        return (
            <div className="panel-request-join">
                <div className="panel-request-join-left">
                    <img
                        src={
                            this.props.lang === 'TH'
                                ? this.state.BannerTH
                                : this.state.BannerEN
                        }
                        alt=""
                    />
                </div>
                <div className="panel-request-join-right">
                    <div style={style11}>
                        <span className="close" onClick={this.props.onClose}>
                            &times;
                        </span>
                    </div>
                    <div style={style6} > <p>{this.state.panelInfo}</p> </div>
                    <div style={style10}>
                        <Input.Textbox
                            title={this.props.locale.FULL_NAME}
                            placeholder={`${this.props.locale.FULL_NAME}...`}
                            disabled={this.state.isLoadding}
                            onChange={fullName => this.nameValidation(fullName)}
                        />
                        <Input.Textbox
                            title={this.props.locale.EMAIL}
                            placeholder={`${this.props.locale.EMAIL.toLowerCase()}...`}
                            disabled={this.state.isLoadding}
                            onChange={email => this.emailValidation(email)}
                        />
                    </div>

                    {this.state.isEmailvalid === false ? (
                        <div style={style5} > {this.props.locale.EMAIL_NOT_VALID} </div>
                    ) : <div style={style5} > <br /> </div>}

                    <div style={style10}>
                        <Input.Textarea2
                            title={this.props.locale.ADDITIONAL_INFORMATION_CLUB_TITLE}
                            placeholder={this.props.locale.ADDITIONAL_INFORMATION_CLUB_REQUEST}
                            disabled={this.state.isLoadding}
                            value={this.state.additionalInfo}
                            onChange={additionalInfo => this.setAdditionalInfo(additionalInfo)}
                        />
                    </div>

                    <div style={style3}>
                        <div style={style4}>
                            <Button.Button8
                                disabled={!this.state.isEmailvalid || !this.state.isNamevalid}
                                style={style12}
                                onClick={() => {
                                    if (this.state.isEmailvalid && this.state.isNamevalid) {
                                        this.onSubmitRequestToClub()
                                    }
                                }}
                            >
                                {this.props.locale.CLUB_SEND_REQUEST}
                            </Button.Button8>
                        </div>
                    </div>
                </div>
                <PopUpInformationModal
                    isOpen={this.state.openInformationModal}
                    handlepopUpInformation={this.handlepopUpInformation}
                    locale={this.props.locale}
                    resultToDisplay={this.state.requestToClubResult}
                    informationType={this.state.informationType}
                />
            </div>
        )
    }
}

export default RequestJoineClub
