import React from 'react'
import s from 'styled-components'

import ImgEdit from '../../images/profile-edit.png'
import ImageNoPreview from '../../images/no-preview.svg'

const ImageAvatar = s.img`
  width: 120px;
  height: 120px;
  border-radius: 80px;
  box-shadow: 0 5px 13px rgba(0,0,0,.15);
`

const ImageEdit = s.img`
  position: absolute;
  bottom: 5px;
  right: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
`

const SpanEmail = s.span`
  margin-top: 15px;
  display: block;
  color: #887979;
  font-size: 14px;
`

const Wrapper = s.div`
  ${props => props.noMarginTop ? 'margin:0 auto;' : 'margin:-70px auto 0 auto;'}
  width: 150px;
  position: relative;
`
const style1 = { display: 'none' }

function onClick(id) {
  return () => {
    document.getElementById(id).click()
  }
}

function UploadProfile(props) {
  return (
    <div className="profile-box__image">
      <Wrapper noMarginTop={props.noMarginTop}>
        <ImageAvatar src={props.value ? props.value : ImageNoPreview} alt=""/>
        <ImageEdit src={ImgEdit} alt="" onClick={onClick(props.id)}/>
      </Wrapper>
      <SpanEmail>{props.email}</SpanEmail>
      <input
        type="file"
        style={style1}
        accept="image/*"
        id={props.id}
        onChange={props.onChange}/>
    </div>
  )
}

export default UploadProfile
