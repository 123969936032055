import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { ConvertISODate2 } from '../../utils/string'
import DatePicker from 'react-datepicker'
import { TimePicker } from 'antd'
import 'react-datepicker/dist/react-datepicker.css'
import 'antd/lib/time-picker/style/css'

import s from 'styled-components'

const ddlStyle = `
  -webkit-appearance: none;
  -moz-appearance : none;
  border: 1px solid #887979;
  border-radius: 10px;
  padding: 15px 20px;
  background: none;
  font-size: 14px;
  
  &:disabled{
    color: #bbbbbb;
    background-color: #efefef;
    cursor: no-drop;
  }
`

const DateTimeWrapper = s.div`
  padding:0;
  margin:0 0 10px 0;
  & input {
    border: 1px solid #887979;
    border-radius: 10px;
    padding: 10px 20px;
  }
  
  & > .react-datepicker-wrapper,
  & > .react-datepicker-wrapper > div,
  & input {
    width: 100%;
  }
  
  & select{ width: 100%; ${ddlStyle} }
  
  & > .__box-time {
    width: 50%;
    float:left;
  }
  
  & > .__left{
    padding:10px 5px 10px 0;
  }
  & > .__right{
    padding:10px 0 10px 5px;
  }
`
const TimePickerWarpper = s.div`
  padding: 0;
  & > .ant-time-picker {
    width: 100%;
  }
  & > .ant-time-picker > .ant-time-picker-input{
    padding: 21px 20px;
    max-height: 43px;
  }
`

const Box1 = s.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-items: stretch;
  align-items: stretch;
  & > .react-datepicker-wrapper > div {
    width: 100%;
  }
`
const styleLabel = { color: '#887979', fontWeight: 'bold' }

function onChangeDate(hour, min, setState, onChange) {
  return date => {
    setState(date)

    let d = moment(date).local()
    d.hours(hour)
    d.minutes(min)

    onChange(d.toJSON())
  }
}

function onChangeTimePicker(date, onChange) {
  return (time, timeString) => {
    let d = moment(date).local()
    d.hours(time.hours())
    d.minutes(time.minutes())

    onChange(d.toJSON())
  }
}

function splitDateTime(datetime) {
  const a = datetime.split('T')
  const time = a[1].split(':')
  const date =
    datetime === '0001-01-01T00:00:00Z'
      ? new Date()
      : new Date(datetime.replace(':00Z', ''))
  return {
    date,
    time,
  }
}

const UCT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
const DatePickerTime = ({
  title,
  placeholder,
  type = 'text',
  disabled = false,
  defaultValue = new Date(),
  onChange = value => value,
}) => {
  const [date, setDate] = useState(new Date())
  const [hour, setHour] = useState('00')
  const [min, setMin] = useState('00')
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (defaultValue !== '') {
      const time = moment(defaultValue).local()

      setDate(new Date(time))
      setHour(time.hours())
      setMin(time.minutes())
      setIsReady(true)
    }
  }, [])

  if (!isReady) {
    return null
  }
  return (
    <div className="form-section">
      <label style={styleLabel}>{title}</label>
      <DateTimeWrapper>
        <Box1>
          <DatePicker
            selected={date}
            onChange={onChangeDate(hour, min, setDate, onChange)}
            dateFormat={'dd/MM/yyyy'}
          />
          <TimePickerWarpper>
            <TimePicker
              format="HH:mm"
              defaultValue={moment(`${hour}:${min}`, 'HH:mm')}
              onChange={onChangeTimePicker(date, onChange)}
            />
          </TimePickerWarpper>
        </Box1>
      </DateTimeWrapper>
    </div>
  )
}

export default DatePickerTime
