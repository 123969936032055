import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Loading from '../../components/loading'

import authActions from '../../state/auth/actions'
// import homeActions from '../../state/home/actions'
import paymentActions from '../../state/payment/actions'

import View from '../../components/presentation/manage-event/payment-history'
import ModalDetail from '../../components/popup/payment-history-detail'
// import { navigate } from 'gatsby-link'
// import queryString from 'query-string'

// import JsonTree from '../components/json-tree'

function handle(props) {
  const [isLoading, setLoading] = useState(true)
  const [keyword, setKeyword] = useState('')
  const [keywordType, setKeywordType] = useState('Name')

  useEffect(function() {
    props.checkAuthorization(null, '/', null, {
      onSuccess: () => {
        setLoading(false)
        props.onLoad({
          search: {
            keyword,
            keywordType,
          },
          callback: () => {
            setLoading(false)
          },
        })
      },
    })
  }, [])

  function onSearch() {
    setLoading(true)
    props.onLoad({
      search: {
        keyword,
        keywordType,
      },
      callback: () => {
        setLoading(false)
      },
    })
  }

  function onKeyword(e) {
    if (e.currentTarget) {
      setKeyword(e.currentTarget.value)
    } else {
      setKeyword(e)
    }
  }

  function onKeywordType(e) {
    if (e.currentTarget.value === 'date') {
      const d = new Date()
      const form = moment(d).format('YYYY-MM-DDT00:00:00')
      const to = moment(d).format('YYYY-MM-DDT23:59:59')
      setKeyword({ form: form + '.00Z', to: to + '.00Z' })
    } else {
      setKeyword('')
    }
    setKeywordType(e.currentTarget.value)
  }

  return {
    isLoading,
    keyword,
    keywordType, // [Name,Email,Date,Status]
    onSearch,
    onKeyword,
    onKeywordType,
  }
}

function PaymentHistoryPage(props) {
  const {
    isLoading,
    keyword,
    keywordType,
    onSearch,
    onKeyword,
    onKeywordType,
  } = handle(props)

  if (isLoading) {
    return <Loading />
  }

  return (
    <Layout auth={props.auth.currentUser !== null}>
      <SEO keywords={[`invitree`]} />
      <View
        data={props.state.historyList}
        keyword={keyword}
        keywordType={keywordType}
        onSearch={onSearch}
        onKeyword={onKeyword}
        onKeywordType={onKeywordType}
        onDetail={props.onDetail}
        locale={props.app.locale[props.app.lang]}
      />
      <ModalDetail
        data={props.state.historyDetail}
        locale={props.app.locale[props.app.lang]}
      />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.payment,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: paymentActions.onPaymentHistoryLoad,
      onDetail: paymentActions.onPaymentHistoryDetail,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentHistoryPage)
