import React from 'react'
import s from 'styled-components'
import Link from 'gatsby-link'

import IconLogo from '../../images/logo_icon.svg'
import IconFacebook from '../../images/icons/icon_facebook.png'
import IconTwitter from '../../images/icons/icon_twitter.png'
import IconYoutube from '../../images/icons/icon_youtube.png'
import IconInstragram from '../../images/icons/icon_instragram.png'

function goFacebook() {
  window.location = 'https://www.facebook.com'
}

function goTwitter() {
  window.location = 'https://twitter.com'
}

function goYoutube() {
  window.location = 'https://www.youtube.com'
}

function goInstragram() {
  window.location = 'https://www.instagram.com'
}

const Box = s.div`
    display: grid;
    grid-template-columns: 45% 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    justify-items: stretch;
    align-items: stretch;
`

const SubBox = s.div`
    display: grid;
    grid-template-columns: 45% 1fr;
`

const TextFollow = s.p`text-align: right;padding-right: 40px;color: #887979;`
const ContractList = s.ul`
  & > li, a {
    color: #887979;
  }
`
const style1 = { marginRight: '13px' }
const style2 = { width: 20, cursor: 'pointer' }
const style3 = { width: '100%', float: 'left' }

export default (props) => {
  return (
    <footer className="footer">
      <div className="footer-bottom">
        <div className="default-container">
          <Box className="footer-bottom-info">
            <div>
              <div className="copyright">
                <img src={IconLogo} alt="" style={style1} />© 2019
                www.invitree.me All rights reserved.
              </div>
            </div>
            { (window.location.pathname == '/KMITLEngineerAlumni' || props.clubRegister.joined == true) ? null :
            (
            <SubBox className="footer-bottom-info-contact">
              <div style={style3}>
                <ContractList>
                  <li>Contact Us</li>
                  <li>Email: customerservice@invitree.me</li>
                  <li>Call Center: 02-0579135</li>
                  <li>
                    <Link to="/term-of-use">Terms</Link>
                    &nbsp; • &nbsp;
                    <Link to="/privacy-policy">Privacy</Link>
                  </li>
                </ContractList>
              </div>
              <div className="footer-bottom-info-follow">
                <TextFollow>Follow Us</TextFollow>
                <ul className="footer-nav">
                  <li>
                    <img
                      src={IconFacebook}
                      alt=""
                      style={style2}
                      onClick={goFacebook}
                    />
                  </li>
                  <li>
                    <img
                      src={IconTwitter}
                      alt=""
                      style={style2}
                      onClick={goTwitter}
                    />
                  </li>
                  <li>
                    <img
                      src={IconYoutube}
                      alt=""
                      style={style2}
                      onClick={goYoutube}
                    />
                  </li>
                  <li>
                    <img
                      src={IconInstragram}
                      alt=""
                      style={style2}
                      onClick={goInstragram}
                    />
                  </li>
                </ul>
              </div>
            </SubBox>
            )}
          </Box>
        </div>
      </div>
    </footer>
  )
}
