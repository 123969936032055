import React from 'react'
import swal from 'sweetalert'

import Input from '../form/input'
import Button from '../form/buttons'
import ImageCover from '../../images/change-password-cover.png'

export default class ChangePasswordComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      oldPassword: '',
      password: '',
      confirmPassword: '',
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.auth.currentUser) {
      this.setState({ username: this.props.auth.currentUser })
    }
  }

  onSubmit() {
    if (
      this.state.oldPassword === '' ||
      this.state.password === '' ||
      this.state.confirmPassword === ''
    ) {
      swal(
        this.props.locale.ERROR,
        `${this.props.locale.ERROR_PASSWORD_INCORRECT}!`,
        'error'
      )
    } else if (this.state.oldPassword === this.state.password) {
      swal(
        this.props.locale.ERROR,
        `${this.props.locale.ERROR_PASSWORD_NOT_MATCH}!`,
        'error'
      )
    } else if (this.state.password !== this.state.confirmPassword) {
      swal(
        this.props.locale.ERROR,
        `${this.props.locale.ERROR_PASSWORD_NOT_MATCH}!`,
        'error'
      )
    } else {
      this.props.onSubmit(
        this.state.username,
        this.state.oldPassword,
        this.state.password
      )
    }
  }

  onChangeOldPassword = oldPassword => this.setState({ oldPassword })
  onChangePassword = password => this.setState({ password })
  onChangeConfirmPassword = confirmPassword =>
    this.setState({ confirmPassword })

  render() {
    return (
      <div className="panel-sign-in">
        <div className="panel-sign-in-left">
          <img
            src={
              this.props.lang === 'EN'
                ? process.env.GATSBY_IMG_CHANGE_PASSWORD_EN
                : process.env.GATSBY_IMG_CHANGE_PASSWORD_TH
            }
            alt=""
          />
        </div>
        <div className="panel-sign-in-right">
          <div style={{ padding: '10px 10px 30px 0' }}>
            <span className="close" onClick={this.props.onClose}>
              &times;
            </span>
          </div>
          <Input.Textbox
            title={this.props.locale.OLD_PASSWORD}
            placeholder={`${this.props.locale.OLD_PASSWORD.toLowerCase()}...`}
            type="password"
            onChange={this.onChangeOldPassword}
          />
          <Input.Textbox
            title={this.props.locale.NEW_PASSWORD}
            placeholder={`${this.props.locale.NEW_PASSWORD.toLowerCase()}...`}
            type="password"
            onChange={this.onChangePassword}
          />
          <Input.Textbox
            title={this.props.locale.CONFIRM_PASSWORD}
            placeholder={`${this.props.locale.CONFIRM_PASSWORD.toLowerCase()}...`}
            type="password"
            onChange={this.onChangeConfirmPassword}
          />
          <div style={{ textAlign: 'center', padding: 10 }}>
            <div style={{ padding: 5 }}>
              <Button.Button4 onClick={this.onSubmit}>
                {this.props.locale.CHANGE}
              </Button.Button4>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
