import React from 'react'
import s from 'styled-components'

const Box = s.div`
  padding-left: 20px;
  padding-right: 20px;
`
const Header = s.div`
  text-align: center;
  border-bottom: 0.75pt solid #E5E5E6;
  margin-top: 20px;
  margin-bottom: 20px;
`
const Body = s.div`
  text-align: justify;
  color: black;
  margin-bottom: 20px;
`
const H1 = s.p`
  color: black;
  font-size: 2em;
  margin-bottom: 10px;
  font-weight: bold;
`
const H2 = s.p`
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`
const H3 = s.p`
  color: gray;
`
const Ul = s.ul`
  padding-left: 40px;
`
const Bullet = s.li`
  list-style-type: disc;
`

export default () => (
  <Box>
    <Header>
      <H1>นโยบายความเป็นส่วนตัว</H1>
      <H3>อัพเดทล่าสุด 14/03/2019</H3>
    </Header>
    <Body>
      <H2>นโยบายความเป็นส่วนตัวของ www.invitree.me</H2>
      <p>
        บริษัท สไปรท์ คอนซัลติ้ง จำกัด ("บริษัท") ซึ่งรวมถึงเว็บไซต์
        www.invitree.me
        ตระหนักถึงความปลอดภัยของข้อมูลส่วนบุคคลและจะรักษาข้อมูลที่ได้รับจากท่านไว้อย่างเป็นความลับและ
        ปลอดภัยโดยใช้นโยบายความเป็นส่วนตัวเพื่อป้องกันความเสียหายที่อาจเกิดขึ้นกับท่าน
        บริษัทจึงมีนโยบายความเป็นส่วนตัวเพื่อป้องกันความปลอดภัยของข้อมูลส่วนตัวของท่านดังนี้
      </p>

      <H2>การเก็บข้อมูลและการใช้ข้อมูลส่วนบุคคล</H2>

      <p>
        เมื่อท่านใช้บริการผ่านทางเว็บไซต์ www.invitree.me
        และลงทะเบียนบัญชีผู้ใช้ ท่านจะต้องให้ข้อมูลส่วนตัวกับทางเว็บไซต์ เช่น
        ชื่อ ที่อยู่ อีเมล เบอร์โทรศัพท์ ข้อมูลบัญชีธนาคาร ข้อมูลบัตรเครดิต
        รวมถึงข้อมูลลักษณะการใช้งานเว็บไซต์ เป็นต้น
        บริษัทตกลงจะเก็บข้อมูลเหล่านี้ไว้เป็นความลับ
        และบริษัทอาจใช้ข้อมูลดังกล่าวเพื่อ
      </p>

      <Ul>
        <Bullet>
          ประมวลผลและบริหารการใช้งานเว็บไซต์ของท่าน รวมถึงผู้ใช้งานอื่น
        </Bullet>
        <Bullet>
          สื่อสารกับท่านทางโทรศัพท์ อีเมล
          หรือข้อความทางมือถือเกี่ยวกับการใช้งานเว็บไซต์
        </Bullet>
        <Bullet>
          ตอบคำถามของท่านเกี่ยวกับการให้บริการลูกค้า
          หรือกระทำการอื่นใดเพื่อตอบคำถามของท่านหรือตอบสนองกิจกรรมในเว็บไซต์อื่นๆของท่าน
        </Bullet>
        <Bullet>
          สร้างการส่งเสริมการขายเฉพาะกลุ่มโดยการรวมข้อมูลส่วนตัวของท่านกับข้อมูลที่ไม่เป็นข้อมูลส่วนตัวของท่าน
          เช่นจำนวนครั้งและประเภทการซื้อของท่าน หรือประโยชน์ใดๆ
          ที่ท่านได้รับผ่านเว็บไซต์
        </Bullet>
        <Bullet>
          จัดทำข้อเสนอการขายเฉพาะกลุ่มโดยพิจารณาจากข้อมูลการใช้งานเว็บไซต์ส่วนบุคคล
        </Bullet>
      </Ul>

      <H2>การเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลภายนอก</H2>

      <p>
        บริษัทจะไม่เปิดเผยข้อมูลส่วนบุคคลใดๆ ของท่านให้แก่บุคคลภายนอก เว้นแต่
      </p>

      <Ul>
        <Bullet>ได้รับความยินยอมจากท่าน</Bullet>
        <Bullet>
          เป็นการเปิดเผยให้กับผู้จัดงาน หรือผู้จัดการแสดงต่าง ๆ ทั้งนี้
          เฉพาะข้อมูลส่วนบุคคลที่เกี่ยวข้องกับงานหรือการแสดงที่เกี่ยวข้อง
        </Bullet>
        <Bullet>
          เป็นการเปิดเผยให้กับบริการอื่นซึ่งสนับสนุนการใช้งานเว็บไซต์ เช่น
          ผู้ประมวลบัตรเครดิต ผู้ให้บริการด้านการชำระเงิน หรือ ผู้ให้บริการอีเมล
          เป็นต้น
        </Bullet>
        <Bullet>
          ข้อมูลบางส่วนอาจเปิดเผย เมื่อมีคำร้องขอโดยชอบด้วยกฎหมาย
          จากหน่วยงานทางกฎหมาย หรือเพื่อปกป้องสิทธิ ทรัพย์สิน
          และความปลอดภัยของบริษัท พนักงาน ตัวแทน และผู้ใช้งานเว็บไซต์ท่านอื่น
          เป็นต้น
        </Bullet>
        <Bullet>
          กรณีมีการโอนกิจการ ควบรวมกิจการ การขายสินทรัพย์ หรือการกู้ยืมเงิน
          บริษัทอาจต้องเปิดเผยข้อมูลบางส่วนให้กับผู้ที่เกี่ยวข้องในการทำธุรกรรมดังกล่าวทราบ
        </Bullet>
      </Ul>

      <H2>การแก้ไขนโยบายความปลอดภัยและข้อปฏิเสธความรับผิดชอบ</H2>

      <p>
        บริษัทขอสงวนสิทธิในการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวและข้อปฏิเสธความรับผิดชอบได้ทุกเมื่อ
        เนื้อหาทั้งหมดในหน้าเว็บไซต์นี้เป็นเนื้อหาที่ปรับปรุงล่าสุด
        ทางบริษัทจะแจ้งข้อมูลใหม่ให้ท่านทราบเมื่อมีการปรับปรุงข้อมูลเพื่อท่านจะได้ทราบถึงวิธีการป้องกันข้อมูล
        ส่วนตัวของลูกค้า
      </p>

      <H2>การคุ้มครองความปลอดภัยในการเข้าถึงข้อมูลส่วนบุคคล</H2>
      <p>
        บริษัทมีการดำเนินการตามสมควรในการรักษาความปลอดภัยด้านกายภาพ เทคนิค
        และการจัดการเพื่อป้องกันความสูญเสีย การใช้งานอันไม่สมควร
        การเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต
        การเปิดเผยหรือปรับเปลี่ยนข้อมูลส่วนบุคคล อย่างไรก็ตาม
      </p>
      <p>
        บริษัทขอสงวนความรับผิดในการคุ้มครองความปลอดภัยดังกล่าว
        เนื่องจากไม่มีระบบหรือการส่งข้อมูลใดทางเครือข่ายสาธารณะใดที่จะสามารถรับประกันความปลอดภัยได้โดยสมบูรณ์
      </p>
    </Body>
  </Box>
)
