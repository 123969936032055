import React from 'react'
import { navigate } from 'gatsby-link'

import { getLanguage } from '../../../locale'
import ListView from '../../mobile/list-view-payment-history'

import {
  ConvertISODate1,
  convertTransactionOrderType,
  toUpperCaseFirst,
} from '../../../utils/string'

import {
  MobileWrapper,
  PcBoxWrapperSearch,
  PCWrapper,
  SearchForm,
} from './share'

const lang = getLanguage().lang

const dropdownItems = locale => [
  { id: 'id', title: locale.TRANSACTION_ID },
  { id: 'date', title: locale.DATE },
  { id: 'event', title: locale.EVENT },
  { id: 'order_type', title: locale.TYPE },
  { id: 'payment_method', title: locale.CHANNEL },
  { id: 'amount', title: locale.AMOUNT },
  { id: 'status', title: locale.STATUS },
]

const dynamicSearch = {
  date: true,
}

const onDetail = (fn, payload) => fn.bind(null, payload)

const convertPaymentMethodString = (payment_method, locale) => {
  switch (payment_method) {
    case 'internet_banking':
      return locale.TRANSACTION_PAYMENT_INTERNET_BANKING
    case 'credit_card':
      return locale.TRANSACTION_PAYMENT_CARD
    default:
      return toUpperCaseFirst(payment_method)
  }
}
const convertStatusString = (status, locale) => {
  switch (status) {
    case 'failed':
      return locale.TRANSACTION_STATUS_FAILED
    case 'expired':
      return locale.TRANSACTION_STATUS_EXPIRED
    case 'pending':
      return locale.TRANSACTION_STATUS_PENDING
    case 'reversed':
      return locale.TRANSACTION_STATUS_REVERSED
    case 'successful':
      return locale.TRANSACTION_STATUS_SUCCESSFUL
    default:
      return toUpperCaseFirst(status)
  }
}
const onGoView = v => {
  if (v.order) {
    if (v.order.event_id > 0) {
      navigate(`/event-detail/edit/?id=${v.order.event_id}`)
    }
  }
  return false
}

function PcView(props) {
  return (
    <React.Fragment>
      <PCWrapper>
        <PcBoxWrapperSearch>
          <div>
            <SearchForm
              data={dropdownItems(props.locale)}
              dynamicSearch={dynamicSearch}
              keyword={props.keyword}
              keywordType={props.keywordType}
              onKeyword={props.onKeyword}
              onKeywordType={props.onKeywordType}
              onSearch={props.onSearch}
            />
          </div>
        </PcBoxWrapperSearch>
        <div>
          <table className="transaction-table">
            <thead>
              <tr>
                <th>{props.locale.DATE}</th>
                <th>{props.locale.TRANSACTION_ID}</th>
                <th>{props.locale.EVENT}</th>
                <th>{props.locale.TYPE}</th>
                <th>{props.locale.CHANNEL}</th>
                <th>
                  {props.locale.AMOUNT} ({props.locale.THB})
                </th>
                <th>{props.locale.STATUS}</th>
              </tr>
            </thead>
            <tbody>
              {props.data.length > 0 ? (
                props.data.map((v, i) => {
                  return (
                    <tr key={`transaction-table-${i}`}>
                      <td>{ConvertISODate1(v.created_at)}</td>
                      <td>{v.id}</td>
                      <td>
                        <span className="span-link" onClick={() => onGoView(v)}>
                          {(lang === 'TH'
                            ? v.event_title_en
                            : v.event_title_th) || '-'}
                        </span>
                      </td>
                      <td>
                        {v.order
                          ? convertTransactionOrderType(
                              v.order.order_type,
                              props.locale
                            ) || '-'
                          : '-'}
                      </td>
                      <td>
                        {convertPaymentMethodString(
                          v.payment_method,
                          props.locale
                        )}
                      </td>
                      <td>{v.amount}</td>
                      <td>{convertStatusString(v.status, props.locale)}</td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>-- {props.locale.NO_DATA} --</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </PCWrapper>
    </React.Fragment>
  )
}

function MobileView(props) {
  return (
    <MobileWrapper>
      <ListView
        data={props.data}
        locale={props.locale}
        onClick={payload => {
          props.onDetail(payload)
        }}
      />
    </MobileWrapper>
  )
}

PcView.defaultProps = {
  data: [],
  keyword: '',
  keywordType: '',
  onSearch: null,
  onKeyword: null,
  onKeywordType: null,
  onDetail: null,
  locale: null,
}

MobileView.defaultProps = {
  data: [],
  keyword: '',
  keywordType: '',
  onSearch: null,
  onKeyword: null,
  onKeywordType: null,
  onDetail: null,
  locale: null,
}

export default props => (
  <div className="transaction-page">
    <MobileView
      data={props.data}
      keyword={props.keyword}
      keywordType={props.keywordType}
      onSearch={props.onSearch}
      onKeyword={props.onKeyword}
      onKeywordType={props.onKeywordType}
      onDetail={props.onDetail}
      locale={props.locale}
    />
    <PcView
      data={props.data}
      keyword={props.keyword}
      keywordType={props.keywordType}
      onSearch={props.onSearch}
      onKeyword={props.onKeyword}
      onKeywordType={props.onKeywordType}
      onDetail={props.onDetail}
      locale={props.locale}
    />
  </div>
)
