import React from 'react'
import s from 'styled-components'

import Input from '../form/input'
import Button from '../form/buttons'
import TermEN from '../term-and-policy/term-en'
import TermTH from '../term-and-policy/term-th'
import PolicyTH from '../term-and-policy/policy-th'
import PolicyEN from '../term-and-policy/policy-en'
import LineIcon from '../../images/icon-line-white.png'

import {
  AuthenticationHelper,
  facebookLogin,
  LineLogin,
} from '../../utils/auth-utils'

import SignInWithApple from './sign-in-with-apple'

const SpanAgree = s.span`margin-left:2px;color: #892a24 !important;text-decoration: underline !important;    cursor: pointer;`
const style1 = { padding: '10px 10px 30px 0' }
const style2 = { padding: 10, color: 'red', textAlign: 'center' }
const style3 = { textAlign: 'center', padding: 10 }
const style4 = { textAlign: 'center', padding: 5 }
const style5 = { padding: '7px 5px' }
const style6 = { padding: 10 }
const style7 = { width: '100%' }
const style8 = { padding: '10px 10px 10px 0' }
const style9 = { fontSize: 13 }
const style10 = { width: '100%', maxWidth: 400, margin: '0 auto' }

export default class SignUpComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAgree: false,
      ui: 'INDEX',
      fullName: '',
      email: '',
      phoneNumber: '',
      password: '',
      password2: '',
      error: null,
      isLoadding: false,
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit() {
    const Process = () => {
      var phoneNumber = this.state.phoneNumber
      if (phoneNumber.length > 0) {
        if (phoneNumber.length === 9) {
          if (phoneNumber.indexOf('+66') < 0) {
            phoneNumber = '+66' + phoneNumber
          }
        } else {
          this.setState({ error: "Invalid phone number format", isLoadding: false })
          return
        }
      }
      AuthenticationHelper.signUp({
        fullName: this.state.fullName,
        email: this.state.email,
        phone_number: phoneNumber,
        password: this.state.password,
        password2: this.state.password2,
        onSuccess: userDetail => {
                      console.log(userDetail)
                      this.props.onSubmit(userDetail)},
        onError: errorMessage =>
          this.setState({ error: errorMessage, isLoadding: false }),
      })
    }
    this.setState({ isLoadding: true }, Process)
  }

  onSignFacebook = () => this.setState({ isLoadding: true }, facebookLogin)
  onSignLine = () => this.setState({ isLoadding: true }, LineLogin)

  toggleAgree = () => this.setState({ isAgree: !this.state.isAgree })
  isDisableSubmit = () =>
    this.state.isLoadding ? this.state.isLoadding : !this.state.isAgree
  onTermsClick = () => this.setState({ ui: 'TERM' })
  onPolicyClick = () => this.setState({ ui: 'POLICY' })
  onBackToIndex = () => this.setState({ ui: 'INDEX' })
  onFullNameChange = fullName => this.setState({ fullName, error: null })
  onEmailChange = email => this.setState({ email, error: null })
  onPhoneNumberChange = phoneNumber => this.setState({phoneNumber, error: null})
  onPasswordChange = password => this.setState({ password, error: null })
  onPassword2Change = password2 => this.setState({ password2, error: null })

  conditionRender() {
    if (this.state.ui === 'INDEX') {
      return (
        <React.Fragment>
          <div className="panel-sign-up-left">
            <img
              src={
                this.props.lang === 'TH'
                  ? process.env.GATSBY_IMG_SIGN_UP_TH
                  : process.env.GATSBY_IMG_SIGN_UP_EN
              }
              alt=""
            />
          </div>
          <div className="panel-sign-in-right">
            <div style={style1}>
              <span className="close" onClick={this.props.onClose}>
                &times;
              </span>
            </div>
            <div style={style10}>
              <Input.Textbox2
                title={this.props.locale.FULL_NAME}
                placeholder={`${this.props.locale.FULL_NAME.toLowerCase()}...`}
                disabled={this.state.isLoadding}
                value={this.state.fullName}
                onChange={this.onFullNameChange}
              />
              <Input.Textbox2
                title={this.props.locale.EMAIL}
                placeholder={`${this.props.locale.EMAIL.toLowerCase()}...`}
                disabled={this.state.isLoadding}
                value={this.state.email}
                onChange={this.onEmailChange}
              />
              <Input.PhoneNumber
                title={this.props.locale.PHONE_NUMBER}
                name="phone"
                placeholder={`${this.props.locale.PHONE_NUMBER_PLACEHOLDER}`}
                disabled={this.state.isLoadding}
                defaultValue={this.state.phoneNumber}
                onChange={this.onPhoneNumberChange}
              />
              <Input.Textbox2
                title={this.props.locale.PASSWORD}
                placeholder={`${this.props.locale.PASSWORD.toLowerCase()}...`}
                type="password"
                disabled={this.state.isLoadding}
                value={this.state.password}
                onChange={this.onPasswordChange}
              />
              <Input.Textbox2
                title={this.props.locale.CONFIRM_PASSWORD}
                placeholder={`${this.props.locale.CONFIRM_PASSWORD.toLowerCase()}...`}
                type="password"
                disabled={this.state.isLoadding}
                value={this.state.password2}
                onChange={this.onPassword2Change}
              />

              {this.state.error && <p style={style2}>{this.state.error}</p>}

              <div style={style3}>
                <Input.CheckBox
                  value={this.state.isAgree}
                  onChange={this.toggleAgree}
                >
                  {this.props.locale.I_AGREE[0]}
                  <SpanAgree onClick={this.onTermsClick}>
                    {this.props.locale.I_AGREE[1]}
                  </SpanAgree>{' '}
                  {this.props.locale.I_AGREE[2]}
                  <SpanAgree onClick={this.onPolicyClick}>
                    {this.props.locale.I_AGREE[3]}
                  </SpanAgree>
                </Input.CheckBox>
              </div>

              <div style={style4}>
                <div style={style5}>
                  <Button.Button4
                    onClick={this.onSubmit}
                    style={style9}
                    disabled={this.isDisableSubmit()}
                  >
                    {this.props.locale.SIGN_UP}
                  </Button.Button4>
                </div>

                <div style={style5}>
                  <Button.ButtonFacebookSignIn onClick={this.onSignFacebook}>
                    Continue with Facebook
                  </Button.ButtonFacebookSignIn>
                </div>

                <div style={style5}>
                  <Button.ButtonLineLogin onClick={this.onSignLine}>
                    <img src={LineIcon} alt="" />
                    Continue with LINE
                  </Button.ButtonLineLogin>
                </div>

                <SignInWithApple />

                <div style={style6}>
                  <span className="span-link" onClick={this.props.onSignIn}>
                    {this.props.locale.ALREADY_ACCOUNT}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    } else if (this.state.ui === 'POLICY') {
      return (
        <div style={style7}>
          <div style={style8}>
            <span className="close" onClick={this.onBackToIndex}>
              &times;
            </span>
            {this.props.lang === 'TH' ? <PolicyTH /> : <PolicyEN />}
          </div>
          <div style={style4}>
            <Button.Button4 onClick={this.onBackToIndex}>
              {this.props.locale.CLOSE}
            </Button.Button4>
          </div>
        </div>
      )
    } else if (this.state.ui === 'TERM') {
      return (
        <div style={style7}>
          <div style={style8}>
            <span className="close" onClick={this.onBackToIndex}>
              &times;
            </span>
            {this.props.lang === 'TH' ? <TermTH /> : <TermEN />}
          </div>
          <div style={style4}>
            <Button.Button4 onClick={this.onBackToIndex}>
              {this.props.locale.CLOSE}
            </Button.Button4>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    return <div className="panel-sign-in">{this.conditionRender()}</div>
  }
}
